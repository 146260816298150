@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,300i,400,400i,500,500i&subset=greek");
@import url(../../../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css);
.font-300, body, body p,
body div,
body span, .custom-select-wrapper .custom-select, .custom-select-wrapper .Select .Select-control .Select-input > input, .form-control, .floating-label-wrapper .form-control, .list-form .coordinates-wrapper .floating-label-wrapper .form-control, .list-form .name-wrapper .floating-label-wrapper .form-control, .datepicker-wrapper .btn, .datepicker-wrapper #main .yacht-nav-link span, #main .yacht-nav-link .datepicker-wrapper span, .login-wrapper .form-wrap h4, .login-wrapper.home-wrapper .home-link > span.link-head, #main .result-yacht-wrapper .heading-body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300; }

.font-400, .notifications-wrapper .notification-action-wrapper .notification-action-button, .yacht-category, .tooltip .tooltip-inner, .btn, #main .yacht-nav-link span, .form-label, .toggle-wrapper.inline-toggle .toggle .toggle-label, .list-form .coordinates-wrapper .extra-options-info, .tags-wrapper .tags-list .tag-tab-info, .tags-wrapper .tags-list .tag-tab-info-error, .brokers-wrap .brokers-list > li .btn-remove span,
.brokers-wrap .companies-list > li .btn-remove span,
.brokers-wrap .destinations-multiple-list > li .btn-remove span,
.companies-wrap .brokers-list > li .btn-remove span,
.companies-wrap .companies-list > li .btn-remove span,
.companies-wrap .destinations-multiple-list > li .btn-remove span,
.destinations-multiple-wrap .brokers-list > li .btn-remove span,
.destinations-multiple-wrap .companies-list > li .btn-remove span,
.destinations-multiple-wrap .destinations-multiple-list > li .btn-remove span, .upload-panel .upload-single-image .btn, .table-actions .action-label, .gallery-photos .gallery-photos-prewrap .optional, .datepicker-wrapper .date-dropdown .side-links-wrap .btn, .datepicker-wrapper .date-dropdown .rdt .rdtPicker thead th, #sidebar .sidebar-head .sidebar-user .user-info, #sidebar .sidebar-body .sidebar-list > li > a span, #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading, #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap a, #main .destinations-manage .suggestions-wrap .suggestions-list > div > button > div {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400; }

.font-500, strong,
b,
.redactor-styles strong,
.redactor-styles b, .small-caps, .comments-wrapper .comment-wrap .comment-author .comment-name, .comments-pagination-wrapper button, .btn.btn-link, #main .yacht-nav-link span.btn-link, .custom-select-wrapper .custom-select-label, .custom-select-wrapper .Select .Select-control .Select-placeholder, .floating-label-wrapper .float, .radio-group-label, .toggle-wrapper .toggle-label, .list-form .coordinates-wrapper .extra-options-wrap, .tags-wrapper .tags-list .tag-label, label.upload-label, .upload-panel label.upload-input .input-wrap, .upload-panel label.upload-input .input-wrap div, .upload-panel .description, .upload-panel .upload-single-image > a.close-button, .table .table-header .header-col > span, .table .pagination-wrapper .table-pagination-wrapper .custom-select-wrapper .custom-select, .gallery-photos .gallery-photos-prewrap .gallery-label, .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li a.close-button, .gallery-image-box a.close-button, .gallery-image-box-with-selection a.close-button, .collapse-wrap .collapse-label, .label-seperator .seperator-title, .redactor-wrap .redactor-label, .datepicker-wrapper label, .login-wrapper .form-wrap strong, .login-wrapper.home-wrapper .home-link > span.link-text, .login-wrapper.home-wrapper .home-divader, .header > .row .active-nav > li > a, #sidebar .sidebar-head .sidebar-user .user-name span, #main .result-yacht-wrapper .heading-label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #3293f4;
    background-color: #3293f4; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #abd3fa; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #dbecfd;
    border-color: #dbecfd; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.3rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3293f4;
  background-color: #3293f4; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(50, 147, 244, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(50, 147, 244, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(50, 147, 244, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.3rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(50, 147, 244, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
  color: #41506e;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #abd3fa;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }
    .custom-select:focus::-ms-value {
      color: #41506e;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #41506e; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.4rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 2rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #abd3fa;
    box-shadow: 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 300;
  line-height: 1;
  color: #41506e;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1;
    color: #41506e;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #3293f4;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #dbecfd; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3293f4;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #dbecfd; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #3293f4;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #dbecfd; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
  color: #aab4c7;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 3.2rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #3293f4;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #3293f4;
    text-decoration: none; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 3.2rem;
  font-weight: 300;
  line-height: 1.2; }

h1, .h1 {
  font-size: 4rem; }

h2, .h2 {
  font-size: 3.2rem; }

h3, .h3 {
  font-size: 2.8rem; }

h4, .h4 {
  font-size: 2.4rem; }

h5, .h5 {
  font-size: 2rem; }

h6, .h6 {
  font-size: 1.6rem; }

.lead {
  font-size: 2rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 6.4rem;
  font-size: 2rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1056px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1;
  color: #41506e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #41506e; }
  .form-control:focus {
    color: #41506e;
    background-color: #fff;
    border-color: #abd3fa;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #41506e;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 2rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.4rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1;
  color: #aab4c7;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5fcf80; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.4rem;
  line-height: 1;
  color: #aab4c7;
  background-color: rgba(95, 207, 128, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #5fcf80;
  padding-right: calc(1em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235fcf80' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.1875rem) center;
  background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #5fcf80;
    box-shadow: 0 0 0 0.2rem rgba(95, 207, 128, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1em + 0.75rem);
  background-position: top calc(0.25em + 0.1875rem) right calc(0.25em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5fcf80;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235fcf80' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.5em + 0.375rem) calc(0.5em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #5fcf80;
    box-shadow: 0 0 0 0.2rem rgba(95, 207, 128, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5fcf80; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5fcf80; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #5fcf80; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #86db9f;
  background-color: #86db9f; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(95, 207, 128, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5fcf80; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5fcf80; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5fcf80;
  box-shadow: 0 0 0 0.2rem rgba(95, 207, 128, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ee394e; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(238, 57, 78, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ee394e;
  padding-right: calc(1em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ee394e' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ee394e' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.1875rem) center;
  background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ee394e;
    box-shadow: 0 0 0 0.2rem rgba(238, 57, 78, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1em + 0.75rem);
  background-position: top calc(0.25em + 0.1875rem) right calc(0.25em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ee394e;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ee394e' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ee394e' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.5em + 0.375rem) calc(0.5em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #ee394e;
    box-shadow: 0 0 0 0.2rem rgba(238, 57, 78, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ee394e; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ee394e; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ee394e; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f26878;
  background-color: #f26878; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(238, 57, 78, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ee394e; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ee394e; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ee394e;
  box-shadow: 0 0 0 0.2rem rgba(238, 57, 78, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, #main .yacht-nav-link span {
  display: inline-block;
  font-weight: 400;
  color: #aab4c7;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, #main .yacht-nav-link span {
      transition: none; } }
  .btn:hover, #main .yacht-nav-link span:hover {
    color: #aab4c7;
    text-decoration: none; }
  .btn:focus, #main .yacht-nav-link span:focus, .btn.focus, #main .yacht-nav-link span.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }
  .btn.disabled, #main .yacht-nav-link span.disabled, .btn:disabled, #main .yacht-nav-link span:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), #main .yacht-nav-link span:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #3293f4;
  border-color: #3293f4; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0e80f2;
    border-color: #0c7ae7; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0e80f2;
    border-color: #0c7ae7;
    box-shadow: 0 0 0 0.2rem rgba(81, 163, 246, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #3293f4;
    border-color: #3293f4; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0c7ae7;
    border-color: #0c73db; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 163, 246, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #aab4c7;
  background-color: #5fcf80;
  border-color: #5fcf80; }
  .btn-success:hover {
    color: #fff;
    background-color: #42c669;
    border-color: #3ac162; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #42c669;
    border-color: #3ac162;
    box-shadow: 0 0 0 0.2rem rgba(106, 203, 139, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #aab4c7;
    background-color: #5fcf80;
    border-color: #5fcf80; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3ac162;
    border-color: #37b75d; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(106, 203, 139, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #aab4c7;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #aab4c7;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #aab4c7;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(242, 191, 36, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #aab4c7;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #aab4c7;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 191, 36, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #ee394e;
  border-color: #ee394e; }
  .btn-danger:hover {
    color: #fff;
    background-color: #eb162f;
    border-color: #e1132b; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #eb162f;
    border-color: #e1132b;
    box-shadow: 0 0 0 0.2rem rgba(241, 87, 105, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ee394e;
    border-color: #ee394e; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #e1132b;
    border-color: #d51229; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 87, 105, 0.5); }

.btn-light {
  color: #aab4c7;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #aab4c7;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #aab4c7;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(236, 239, 242, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #aab4c7;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #aab4c7;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 239, 242, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #3293f4;
  border-color: #3293f4; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #3293f4;
    border-color: #3293f4; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(50, 147, 244, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #3293f4;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3293f4;
    border-color: #3293f4; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 147, 244, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #5fcf80;
  border-color: #5fcf80; }
  .btn-outline-success:hover {
    color: #aab4c7;
    background-color: #5fcf80;
    border-color: #5fcf80; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(95, 207, 128, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #5fcf80;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #aab4c7;
    background-color: #5fcf80;
    border-color: #5fcf80; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 207, 128, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #aab4c7;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #aab4c7;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #ee394e;
  border-color: #ee394e; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #ee394e;
    border-color: #ee394e; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(238, 57, 78, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ee394e;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ee394e;
    border-color: #ee394e; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(238, 57, 78, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #aab4c7;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #aab4c7;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #3293f4;
  text-decoration: none; }
  .btn-link:hover {
    color: #3293f4;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, #main .yacht-nav-link .btn-group-lg > span {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, #main .yacht-nav-link .btn-group-sm > span {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.6rem;
  color: #aab4c7;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 3.2rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #aab4c7;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #9ba6bd;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #3293f4; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #aab4c7; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, #main .yacht-nav-link .btn-group > span,
  .btn-group-vertical > .btn,
  #main .yacht-nav-link .btn-group-vertical > span {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, #main .yacht-nav-link .btn-group > span:hover,
    .btn-group-vertical > .btn:hover,
    #main .yacht-nav-link .btn-group-vertical > span:hover {
      z-index: 1; }
    .btn-group > .btn:focus, #main .yacht-nav-link .btn-group > span:focus, .btn-group > .btn:active, #main .yacht-nav-link .btn-group > span:active, .btn-group > .btn.active, #main .yacht-nav-link .btn-group > span.active,
    .btn-group-vertical > .btn:focus,
    #main .yacht-nav-link .btn-group-vertical > span:focus,
    .btn-group-vertical > .btn:active,
    #main .yacht-nav-link .btn-group-vertical > span:active,
    .btn-group-vertical > .btn.active,
    #main .yacht-nav-link .btn-group-vertical > span.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), #main .yacht-nav-link .btn-group > span:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), #main .yacht-nav-link .btn-group > span:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
#main .yacht-nav-link .btn-group > .btn-group:not(:last-child) > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), #main .yacht-nav-link .btn-group > span:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
#main .yacht-nav-link .btn-group > .btn-group:not(:first-child) > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, #main .yacht-nav-link .btn-group-sm > span + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, #main .yacht-nav-link .btn-group-lg > span + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  #main .yacht-nav-link .btn-group-vertical > span,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), #main .yacht-nav-link .btn-group-vertical > span:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), #main .yacht-nav-link .btn-group-vertical > span:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  #main .yacht-nav-link .btn-group-vertical > .btn-group:not(:last-child) > span {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), #main .yacht-nav-link .btn-group-vertical > span:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  #main .yacht-nav-link .btn-group-vertical > .btn-group:not(:first-child) > span {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, #main .yacht-nav-link .btn-group-toggle > span,
.btn-group-toggle > .btn-group > .btn,
#main .yacht-nav-link .btn-group-toggle > .btn-group > span {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], #main .yacht-nav-link .btn-group-toggle > span input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  #main .yacht-nav-link .btn-group-toggle > span input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  #main .yacht-nav-link .btn-group-toggle > .btn-group > span input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  #main .yacht-nav-link .btn-group-toggle > .btn-group > span input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3293f4;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #3293f4;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 147, 244, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3293f4;
  border-color: #3293f4; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 2rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3293f4; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 3.2rem 6.4rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 6.4rem;
  font-size: 2rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 2rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #aab4c7; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #3293f4 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0c7ae7 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #5fcf80 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3ac162 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #ee394e !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e1132b !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #3293f4 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #5fcf80 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #ee394e !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline, .label-seperator .seperator-title {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .modal-open .modal, .btn.btn-blue, .btn.btn-favorite-action > div, #main .yacht-nav-link span.btn-favorite-action > div, .floating-label-wrapper .form-control.form-control-text, .checkbox-wrapper .checkbox .checkmark-box, .radio label span, .toggle-wrapper.inline-toggle .toggle, .toggle-wrapper label > span, .tags-wrapper .tags-list > ul, .brokers-wrap .brokers-list > li .btn-remove,
.brokers-wrap .companies-list > li .btn-remove,
.brokers-wrap .destinations-multiple-list > li .btn-remove,
.companies-wrap .brokers-list > li .btn-remove,
.companies-wrap .companies-list > li .btn-remove,
.companies-wrap .destinations-multiple-list > li .btn-remove,
.destinations-multiple-wrap .brokers-list > li .btn-remove,
.destinations-multiple-wrap .companies-list > li .btn-remove,
.destinations-multiple-wrap .destinations-multiple-list > li .btn-remove, .upload-panel label.upload-input .input-wrap div.upload-icon-photo, .upload-panel .description, .upload-panel .upload-single-image, .upload-panel .upload-single-image .btn, .upload-panel.upload-box, .upload-panel.upload-box .upload-input, .table-actions .extra-table-actions, .table-td-actions, .dashboard-widget-wrapper .widget > .table-header, .collapse-wrap, .datepicker-wrapper, .datepicker-wrapper .date, .datepicker-wrapper .date-dropdown, .header .search-filter, .header > .row .active-nav, .header > .row .active-nav > li, .header > .row .active-nav > li > a, .header .role-switch-wrapper .toggle-wrapper .toggle, .header + .content-inner .search-filter, #sidebar .sidebar-head .sidebar-user, #main .yacht-feature-photo .input-wrap, #main .yacht-nav-link span {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row, .upload-panel.round-photo .upload-single-image {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start, .upload-panel.upload-box .description {
  justify-content: flex-start !important; }

.justify-content-end, .upload-panel .description, .header .role-switch-wrapper .toggle-wrapper .toggle {
  justify-content: flex-end !important; }

.justify-content-center, .btn.btn-favorite-action > div, #main .yacht-nav-link span.btn-favorite-action > div, .upload-panel.upload-box, .upload-panel.upload-box.modal-box .upload-input .input-wrap .upload-icon-photo, .table-td-actions, #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading.yacht-actions li .btn-smaller, #main .yacht-feature-photo .input-wrap {
  justify-content: center !important; }

.justify-content-between, .collapse-wrap, .datepicker-wrapper, .datepicker-wrapper .date {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start, .datepicker-wrapper.disabled .date {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .btn.btn-blue, .btn.btn-favorite-action > div, #main .yacht-nav-link span.btn-favorite-action > div, .custom-select-wrapper .Select .Select-control .Select-value, .floating-label-wrapper .form-control.form-control-text, .checkbox-wrapper .checkbox .checkmark-box, .radio label span, .toggle-wrapper.inline-toggle .toggle, .upload-panel label.upload-input .input-wrap div.upload-icon-photo, .upload-panel .description, .upload-panel .upload-single-image, .upload-panel .upload-single-image .btn, .upload-panel.upload-box, .upload-panel.upload-box .upload-input, .table-td-actions, .dashboard-widget-wrapper .widget > .table-header, .collapse-wrap, .datepicker-wrapper .date, .header .search-filter, .header > .row, .header > .row .active-nav > li, .header > .row .active-nav > li > a, .header .role-switch-wrapper .toggle-wrapper .toggle, .header + .content-inner .search-filter, #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap, #main .yacht-feature-photo .input-wrap, #main .yacht-nav-link span {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0, .toggle-wrapper.inline-toggle .toggle .toggle-label {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 1.6rem !important; }

.mt-1,
.my-1 {
  margin-top: 1.6rem !important; }

.mr-1,
.mx-1 {
  margin-right: 1.6rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 1.6rem !important; }

.ml-1,
.mx-1 {
  margin-left: 1.6rem !important; }

.m-2 {
  margin: 3.2rem !important; }

.mt-2,
.my-2 {
  margin-top: 3.2rem !important; }

.mr-2,
.mx-2 {
  margin-right: 3.2rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 3.2rem !important; }

.ml-2,
.mx-2 {
  margin-left: 3.2rem !important; }

.m-3 {
  margin: 6.4rem !important; }

.mt-3,
.my-3 {
  margin-top: 6.4rem !important; }

.mr-3,
.mx-3 {
  margin-right: 6.4rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 6.4rem !important; }

.ml-3,
.mx-3 {
  margin-left: 6.4rem !important; }

.m-4 {
  margin: 9.6rem !important; }

.mt-4,
.my-4 {
  margin-top: 9.6rem !important; }

.mr-4,
.mx-4 {
  margin-right: 9.6rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 9.6rem !important; }

.ml-4,
.mx-4 {
  margin-left: 9.6rem !important; }

.m-5 {
  margin: 19.2rem !important; }

.mt-5,
.my-5 {
  margin-top: 19.2rem !important; }

.mr-5,
.mx-5 {
  margin-right: 19.2rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 19.2rem !important; }

.ml-5,
.mx-5 {
  margin-left: 19.2rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 1.6rem !important; }

.pt-1,
.py-1 {
  padding-top: 1.6rem !important; }

.pr-1,
.px-1 {
  padding-right: 1.6rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 1.6rem !important; }

.pl-1,
.px-1 {
  padding-left: 1.6rem !important; }

.p-2 {
  padding: 3.2rem !important; }

.pt-2,
.py-2 {
  padding-top: 3.2rem !important; }

.pr-2,
.px-2 {
  padding-right: 3.2rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 3.2rem !important; }

.pl-2,
.px-2 {
  padding-left: 3.2rem !important; }

.p-3 {
  padding: 6.4rem !important; }

.pt-3,
.py-3 {
  padding-top: 6.4rem !important; }

.pr-3,
.px-3 {
  padding-right: 6.4rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 6.4rem !important; }

.pl-3,
.px-3 {
  padding-left: 6.4rem !important; }

.p-4 {
  padding: 9.6rem !important; }

.pt-4,
.py-4 {
  padding-top: 9.6rem !important; }

.pr-4,
.px-4 {
  padding-right: 9.6rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 9.6rem !important; }

.pl-4,
.px-4 {
  padding-left: 9.6rem !important; }

.p-5 {
  padding: 19.2rem !important; }

.pt-5,
.py-5 {
  padding-top: 19.2rem !important; }

.pr-5,
.px-5 {
  padding-right: 19.2rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 19.2rem !important; }

.pl-5,
.px-5 {
  padding-left: 19.2rem !important; }

.m-n1 {
  margin: -1.6rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -1.6rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -1.6rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -1.6rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -1.6rem !important; }

.m-n2 {
  margin: -3.2rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -3.2rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -3.2rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -3.2rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -3.2rem !important; }

.m-n3 {
  margin: -6.4rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -6.4rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -6.4rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -6.4rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -6.4rem !important; }

.m-n4 {
  margin: -9.6rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -9.6rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -9.6rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -9.6rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -9.6rem !important; }

.m-n5 {
  margin: -19.2rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -19.2rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -19.2rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -19.2rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -19.2rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 1.6rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1.6rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1.6rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1.6rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1.6rem !important; }
  .m-sm-2 {
    margin: 3.2rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 3.2rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 3.2rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 3.2rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 3.2rem !important; }
  .m-sm-3 {
    margin: 6.4rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 6.4rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 6.4rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 6.4rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 6.4rem !important; }
  .m-sm-4 {
    margin: 9.6rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 9.6rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 9.6rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 9.6rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 9.6rem !important; }
  .m-sm-5 {
    margin: 19.2rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 19.2rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 19.2rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 19.2rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 19.2rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 1.6rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1.6rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1.6rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1.6rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1.6rem !important; }
  .p-sm-2 {
    padding: 3.2rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 3.2rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 3.2rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 3.2rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 3.2rem !important; }
  .p-sm-3 {
    padding: 6.4rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 6.4rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 6.4rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 6.4rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 6.4rem !important; }
  .p-sm-4 {
    padding: 9.6rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 9.6rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 9.6rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 9.6rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 9.6rem !important; }
  .p-sm-5 {
    padding: 19.2rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 19.2rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 19.2rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 19.2rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 19.2rem !important; }
  .m-sm-n1 {
    margin: -1.6rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1.6rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1.6rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1.6rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1.6rem !important; }
  .m-sm-n2 {
    margin: -3.2rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -3.2rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -3.2rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -3.2rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -3.2rem !important; }
  .m-sm-n3 {
    margin: -6.4rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -6.4rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -6.4rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -6.4rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -6.4rem !important; }
  .m-sm-n4 {
    margin: -9.6rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -9.6rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -9.6rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -9.6rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -9.6rem !important; }
  .m-sm-n5 {
    margin: -19.2rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -19.2rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -19.2rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -19.2rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -19.2rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 1.6rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1.6rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1.6rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1.6rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1.6rem !important; }
  .m-md-2 {
    margin: 3.2rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 3.2rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 3.2rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 3.2rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 3.2rem !important; }
  .m-md-3 {
    margin: 6.4rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 6.4rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 6.4rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 6.4rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 6.4rem !important; }
  .m-md-4 {
    margin: 9.6rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 9.6rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 9.6rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 9.6rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 9.6rem !important; }
  .m-md-5 {
    margin: 19.2rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 19.2rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 19.2rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 19.2rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 19.2rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 1.6rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1.6rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1.6rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1.6rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1.6rem !important; }
  .p-md-2 {
    padding: 3.2rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 3.2rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 3.2rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 3.2rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 3.2rem !important; }
  .p-md-3 {
    padding: 6.4rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 6.4rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 6.4rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 6.4rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 6.4rem !important; }
  .p-md-4 {
    padding: 9.6rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 9.6rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 9.6rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 9.6rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 9.6rem !important; }
  .p-md-5 {
    padding: 19.2rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 19.2rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 19.2rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 19.2rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 19.2rem !important; }
  .m-md-n1 {
    margin: -1.6rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1.6rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1.6rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1.6rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1.6rem !important; }
  .m-md-n2 {
    margin: -3.2rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -3.2rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -3.2rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -3.2rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -3.2rem !important; }
  .m-md-n3 {
    margin: -6.4rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -6.4rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -6.4rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -6.4rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -6.4rem !important; }
  .m-md-n4 {
    margin: -9.6rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -9.6rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -9.6rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -9.6rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -9.6rem !important; }
  .m-md-n5 {
    margin: -19.2rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -19.2rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -19.2rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -19.2rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -19.2rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 1.6rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1.6rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1.6rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1.6rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1.6rem !important; }
  .m-lg-2 {
    margin: 3.2rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 3.2rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 3.2rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 3.2rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 3.2rem !important; }
  .m-lg-3 {
    margin: 6.4rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 6.4rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 6.4rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 6.4rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 6.4rem !important; }
  .m-lg-4 {
    margin: 9.6rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 9.6rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 9.6rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 9.6rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 9.6rem !important; }
  .m-lg-5 {
    margin: 19.2rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 19.2rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 19.2rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 19.2rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 19.2rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 1.6rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1.6rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1.6rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1.6rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1.6rem !important; }
  .p-lg-2 {
    padding: 3.2rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 3.2rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 3.2rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 3.2rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 3.2rem !important; }
  .p-lg-3 {
    padding: 6.4rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 6.4rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 6.4rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 6.4rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 6.4rem !important; }
  .p-lg-4 {
    padding: 9.6rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 9.6rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 9.6rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 9.6rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 9.6rem !important; }
  .p-lg-5 {
    padding: 19.2rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 19.2rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 19.2rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 19.2rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 19.2rem !important; }
  .m-lg-n1 {
    margin: -1.6rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1.6rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1.6rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1.6rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1.6rem !important; }
  .m-lg-n2 {
    margin: -3.2rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -3.2rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -3.2rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -3.2rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -3.2rem !important; }
  .m-lg-n3 {
    margin: -6.4rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -6.4rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -6.4rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -6.4rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -6.4rem !important; }
  .m-lg-n4 {
    margin: -9.6rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -9.6rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -9.6rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -9.6rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -9.6rem !important; }
  .m-lg-n5 {
    margin: -19.2rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -19.2rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -19.2rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -19.2rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -19.2rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 1.6rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1.6rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1.6rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1.6rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1.6rem !important; }
  .m-xl-2 {
    margin: 3.2rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 3.2rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 3.2rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 3.2rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 3.2rem !important; }
  .m-xl-3 {
    margin: 6.4rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 6.4rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 6.4rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 6.4rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 6.4rem !important; }
  .m-xl-4 {
    margin: 9.6rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 9.6rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 9.6rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 9.6rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 9.6rem !important; }
  .m-xl-5 {
    margin: 19.2rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 19.2rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 19.2rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 19.2rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 19.2rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 1.6rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1.6rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1.6rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1.6rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1.6rem !important; }
  .p-xl-2 {
    padding: 3.2rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 3.2rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 3.2rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 3.2rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 3.2rem !important; }
  .p-xl-3 {
    padding: 6.4rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 6.4rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 6.4rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 6.4rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 6.4rem !important; }
  .p-xl-4 {
    padding: 9.6rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 9.6rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 9.6rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 9.6rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 9.6rem !important; }
  .p-xl-5 {
    padding: 19.2rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 19.2rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 19.2rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 19.2rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 19.2rem !important; }
  .m-xl-n1 {
    margin: -1.6rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1.6rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1.6rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1.6rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1.6rem !important; }
  .m-xl-n2 {
    margin: -3.2rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -3.2rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -3.2rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -3.2rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -3.2rem !important; }
  .m-xl-n3 {
    margin: -6.4rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -6.4rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -6.4rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -6.4rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -6.4rem !important; }
  .m-xl-n4 {
    margin: -9.6rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -9.6rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -9.6rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -9.6rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -9.6rem !important; }
  .m-xl-n5 {
    margin: -19.2rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -19.2rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -19.2rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -19.2rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -19.2rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 500 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #3293f4 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0b6dce !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #5fcf80 !important; }

a.text-success:hover, a.text-success:focus {
  color: #34ad58 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #ee394e !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #c91127 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #aab4c7 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media screen and (max-width: 599px) {
  html {
    font-size: 54.6875%; } }

@media screen and (min-width: 600px) {
  html {
    font-size: 62.5%; } }

body {
  font-size: 1.6rem;
  line-height: 1;
  transition: none; }
  body p,
  body div,
  body span {
    font-size: 1.6rem;
    line-height: 1; }

#root {
  min-height: 100vh;
  transition: all 0.3s ease-in-out; }
  #root .content-wrapper {
    min-height: 100vh; }
    #root .content-wrapper > .content:first-of-type {
      min-height: 100vh;
      height: 100%; }

.content {
  position: relative; }

a {
  text-decoration: none !important; }

h1 {
  font-size: 4rem; }

h2 {
  font-size: 2.4rem; }

h3 {
  font-size: 2rem; }

h4 {
  font-size: 1.8rem; }

h5 {
  font-size: 1.4rem; }

strong,
b,
.redactor-styles strong,
.redactor-styles b {
  font-weight: 500 !important; }

.collapse-wrapper .collapse strong {
  line-height: 2rem !important; }

.collapse-wrapper .collapse ul > li,
.collapse-wrapper .collapse ol > li {
  font-size: 1.4rem;
  line-height: 2.4rem !important;
  color: #41506e; }

.seperated-commas {
  line-height: 1.8rem; }

.equal-flex {
  -webkit-box-flex: 1;
  /* OLD - iOS 6-, Safari 3.1-6 */
  width: 20%;
  /* For old syntax, otherwise collapses. */
  -webkit-flex: 1;
  /* Safari 6.1+. iOS 7.1+, BB10 */
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
  /* NEW, Spec - Firefox, Chrome, Opera */ }

.flex-05 {
  flex: 0.5; }

.transition, a, .transition-svg, .double-redactor .redactor-wrap .external-toolbar.hidden, .btn.btn-favorite-action > span, #main .yacht-nav-link span.btn-favorite-action > span, .checkbox-wrapper .checkbox .checkmark-box:after, .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios span, .table .table-header .header-col.sort-icon > span:before, .datepicker-wrapper .date-dropdown .rdt .rdtPicker td, .header > .row .active-nav > li > a:before, #sidebar .sidebar-head .sidebar-user .user-avatar .edit-icon, #sidebar .sidebar-head .sidebar-user .user-avatar .edit-icon svg, #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .collapse-wrap .collapse-label, #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .collapse-wrap .trigger-collapse > button, #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .collapse-wrap .trigger-collapse > button > span {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.no-transition, #main .yachts-inner-wrapper .table-actions > button {
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  -ms-transition: all 0s ease;
  -o-transition: all 0s ease;
  transition: all 0s ease; }

.size-12 {
  font-size: 1.2rem !important; }

.text-main {
  color: #aab4c7 !important; }

.text-sec {
  color: #41506e !important; }

.small-caps {
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #5fcf80; }

.cancel-action {
  fill: #ee394e; }

.cursor-pointer {
  cursor: pointer; }

.notifications-wrapper .notification-action-wrapper {
  text-align: center; }
  .notifications-wrapper .notification-action-wrapper .notification-action-button {
    font-size: 1.4rem;
    outline: 0;
    cursor: pointer;
    min-width: 104px; }

.notifications-wrapper .notification-message {
  line-height: normal;
  font-size: 1.4rem !important; }

.notifications-wrapper .notification-dismiss {
  top: 10px !important;
  right: 8px !important;
  cursor: pointer !important; }

.modal-open .modal {
  align-items: center; }
  .modal-open .modal.lg {
    align-items: flex-start; }
  .modal-open .modal .modal-dialog {
    min-width: 48% !important; }
    @media (min-width: 1500px) {
      .modal-open .modal .modal-dialog {
        min-width: 35% !important; } }

.modal-dialog {
  margin-top: 40px; }
  .modal-dialog .modal-content {
    border-radius: 25px;
    border: none; }
    .modal-dialog .modal-content .modal-header {
      background: #f7f7f7;
      border-radius: 25px;
      margin: 8px;
      padding: 8px 24px;
      border: none; }
      .modal-dialog .modal-content .modal-header .modal-title {
        color: #41506e;
        font-size: 1.4rem;
        font-weight: 400; }
      .modal-dialog .modal-content .modal-header .close {
        background: none;
        border: none;
        outline: none;
        cursor: pointer; }
        .modal-dialog .modal-content .modal-header .close span {
          color: #41506e;
          font-size: 2.4rem;
          font-weight: 400; }
    .modal-dialog .modal-content .modal-body {
      padding: 24px 16px;
      color: #41506e; }
      .modal-dialog .modal-content .modal-body .delete-confirmation .floating-label-wrapper .float {
        display: none; }
      .modal-dialog .modal-content .modal-body .delete-confirmation .floating-label-wrapper .form-control {
        border-bottom-color: #ee394e; }
    .modal-dialog .modal-content .modal-footer {
      padding: 16px 8px;
      border-top-color: #d8dde5; }
      .modal-dialog .modal-content .modal-footer .btn-primary,
      .modal-dialog .modal-content .modal-footer .btn-danger {
        min-width: 104px; }

.collapsing {
  position: static; }
  .collapsing .date-dropdown {
    display: none !important; }
    .collapsing .date-dropdown .rdt {
      display: none; }
    .collapsing .date-dropdown .side-links-wrap {
      display: none; }
  .collapsing .float:not(.top) {
    display: none; }

.history-logs-wrapper {
  max-height: 36rem;
  overflow-y: scroll; }
  .history-logs-wrapper .date {
    margin-bottom: 4px;
    font-size: 1.2rem;
    font-weight: 500; }
  .history-logs-wrapper .desc {
    font-size: 1.4rem; }

.comments-wrapper {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden; }
  .comments-wrapper .comment-wrap .img-rounded {
    border: 1px solid #41506e;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover; }
  .comments-wrapper .comment-wrap .comment-author .comment-name {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 8px; }
  .comments-wrapper .comment-wrap .comment-author .comment-desc p {
    font-size: 1.4rem;
    line-height: 1.4; }
  .comments-wrapper .comment-wrap .comment-date {
    font-size: 1.2rem;
    color: #aab4c7;
    font-weight: 400; }
  .comments-wrapper::-webkit-scrollbar {
    width: 6px; }
  .comments-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
    -webkit-border-radius: 10px;
    border-radius: 10px; }
  .comments-wrapper::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #dedede;
    -webkit-box-shadow: inset 0 0 3px #dedede; }

.comments-pagination-wrapper {
  margin-top: 8px;
  border-top: 1px solid #d8dde5;
  height: 56px; }
  .comments-pagination-wrapper button {
    padding: 0;
    font-size: 1.2rem;
    color: #41506e; }
  .comments-pagination-wrapper div {
    color: #aab4c7;
    font-size: 1.2rem;
    font-weight: 400; }

.role {
  max-width: 140px;
  display: inline-block;
  padding: 8px;
  border-radius: 25px;
  color: #fff !important;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem !important;
  font-weight: 400; }

.yacht-category {
  display: inline-block;
  line-height: 1.6rem;
  color: #fff !important;
  font-size: 1.2rem;
  text-align: center;
  padding: 8px; }
  .yacht-category + .yacht-category {
    margin-left: 8px; }
  .yacht-category.yacht-chartering {
    background: #aab4c7; }
  .yacht-category.yacht-sales {
    background: #41506e; }
  .yacht-category.yacht-yatco {
    background: #003471; }

.yacht-newsletter-category {
  margin-top: 10px !important;
  margin: auto;
  width: 150px; }

.yacht-newsletter-category-field {
  margin-bottom: 10px !important;
  width: 150px; }

.selection-label-wrapper {
  padding: 16px;
  padding-top: 32px; }

.selection-label {
  font-size: 1.2rem;
  color: #41506e;
  font-weight: 500; }

.customer-cat {
  display: inline-block;
  width: 28px;
  height: 28px;
  min-width: 28px !important;
  line-height: 28px !important;
  border-radius: 100%;
  font-size: 14px !important;
  color: #fff !important;
  text-align: center; }
  .customer-cat.customer-chartering {
    background: #5899b7 !important; }
  .customer-cat.customer-sales {
    background: #41506e; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loading {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 0.25rem solid rgba(65, 80, 110, 0.2);
  border-top-color: #41506e;
  animation: spin 1s infinite linear; }

.mt-0 {
  margin-top: 0 !important; }

.mt-05 {
  margin-top: 8px; }

.mb-0 {
  margin-bottom: 0 !important; }

.marginBottom40 {
  margin-bottom: 4rem !important; }

.marginBottom80 {
  margin-bottom: 8rem !important; }

.marginBottom120 {
  margin-bottom: 12rem !important; }

.marginTop40 {
  margin-top: 4rem !important; }

.marginTop10 {
  margin-top: 1rem !important; }

.marginTop20 {
  margin-top: 2rem !important; }

.marginTop60 {
  margin-top: 6rem !important; }

.paddingY24 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important; }

.paddingTop20 {
  padding-top: 2rem !important; }

.paddingTop24 {
  padding-top: 2.4rem !important; }

.paddingTop50 {
  padding-top: 5rem !important; }

.paddingTop60 {
  padding-top: 6rem !important; }

.paddingLeft56 {
  padding-left: 5.6rem !important; }

.hidden {
  display: none !important; }

.flex-direction-column {
  flex-direction: column; }

.bg-blue,
.bg-owner {
  background-color: #3293f4; }

.bg-green,
.bg-admin {
  background-color: #5fcf80; }

.bg-admin_website {
  background-color: #bd2984; }

.bg-super_admin {
  background-color: #ff7300; }

.bg-gold,
.bg-broker,
.bg-broker_sales,
.bg-broker_charter {
  background-color: #edc624; }

.bg-red,
.bg-moderator {
  background-color: #ee394e; }

.white {
  color: #fff !important; }

/* Hide HTML5 Up and Down arrows. */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='number'] {
  -moz-appearance: textfield; }

.comments-svg {
  fill: #5fcf80; }

.history-log {
  fill: #41506e; }

.pdf-svg {
  fill: #ee3e36; }

.print-svg {
  fill: #edc624; }

.favorite-empty {
  fill: #d8dde5; }

.favorite {
  fill: #edc624; }

.lighthouse-svg {
  fill: #d8dde5; }

.vat-paid {
  fill: #5fcf80; }

.vat-unpaid {
  fill: #ee394e; }

.error-svg {
  fill: #ee394e; }

.create-offer-svg:hover {
  fill: #5fcf80 !important; }

.edit-svg:hover,
.wp-svg:hover {
  fill: #3293f4 !important; }

.text-fill-hover .edit-svg,
.text-fill-hover svg {
  fill: #41506e !important; }
  .text-fill-hover .edit-svg:hover,
  .text-fill-hover svg:hover {
    fill: #3293f4 !important; }

.delete-svg:hover {
  fill: #ee394e !important; }

.double-redactor .redactor-wrap .redactor-label {
  margin-bottom: 32px; }

.double-redactor .redactor-wrap:first-child {
  padding-top: 32px; }

.double-redactor .redactor-wrap:nth-child(2) .redactor-layer {
  border-bottom: 0; }

.double-redactor .redactor-wrap:last-child .redactor-toolbar {
  border-top: 0; }

.double-redactor .redactor-wrap .external-toolbar {
  position: absolute;
  width: calc(100% - 16px - 16px);
  top: 0; }
  .double-redactor .redactor-wrap .external-toolbar .redactor-toolbar-external {
    border-color: #d8dde5;
    z-index: 1; }
  .double-redactor .redactor-wrap .external-toolbar.hidden {
    opacity: 0; }

.double-redactor .fixed-text-redactor {
  padding: 8px 16px;
  border-left: 1px solid #d8dde5;
  border-right: 1px solid #d8dde5;
  font-size: 1.7rem;
  color: #41506e;
  position: relative;
  text-align: center !important;
  font-weight: bold !important;
  cursor: not-allowed; }
  .double-redactor .fixed-text-redactor:before, .double-redactor .fixed-text-redactor:after {
    content: '';
    border-top: 1px solid #d8dde5;
    position: absolute;
    top: 0;
    width: calc(100% - 16px - 16px);
    left: 8px; }
  .double-redactor .fixed-text-redactor:after {
    top: auto;
    bottom: 0; }
  .double-redactor .fixed-text-redactor.disabled {
    padding: 0;
    margin-bottom: 16px;
    border: 0; }
    .double-redactor .fixed-text-redactor.disabled:before, .double-redactor .fixed-text-redactor.disabled:after {
      display: none; }

.double-redactor .fixed-signature {
  padding: 8px 16px;
  color: #41506e;
  border: 1px solid #d8dde5;
  border-top-color: white;
  position: relative;
  cursor: not-allowed;
  margin-top: -1px; }
  .double-redactor .fixed-signature img {
    max-width: 100%; }
  .double-redactor .fixed-signature:before {
    content: '';
    border-top: 1px solid #d8dde5;
    position: absolute;
    top: 0;
    width: calc(100% - 16px - 16px);
    left: 8px; }
  .double-redactor .fixed-signature > * {
    font-size: 1.4rem; }
  .double-redactor .fixed-signature :last-child {
    margin-bottom: 0; }
  .double-redactor .fixed-signature.disabled {
    padding: 0;
    border: 0;
    margin: 0; }
    .double-redactor .fixed-signature.disabled:before {
      display: none; }

.empty-customers-message {
  position: absolute;
  top: 24vh;
  text-align: center;
  width: 100%; }
  .empty-customers-message svg {
    fill: #d8dde5; }

.seperator-line {
  position: relative; }
  .seperator-line:after {
    content: '';
    position: absolute;
    width: 98%;
    left: 16px;
    bottom: 0;
    height: 1px;
    z-index: -1;
    background-image: linear-gradient(to right, #aab4c7 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 11px 2px;
    background-repeat: repeat-x;
    opacity: 0.6;
    width: calc(100% - 16px - 16px); }

.drag-handle {
  cursor: move;
  color: #41506e; }

.object-fit-cover {
  object-fit: cover; }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.tooltip.show {
  opacity: 0;
  animation: fade 0.2s ease forwards; }

.tooltip .tooltip-inner {
  font-size: 1.2rem;
  padding: 8px 16px;
  background-color: #41506e;
  border-radius: 14px;
  letter-spacing: 0.4px; }

.tooltip.tooltip-bottom .tooltip-inner:before,
.tooltip.bs-tether-element-attached-top .tooltip-inner:before {
  border-bottom-color: #41506e; }

.tooltip.tooltip-right .tooltip-inner:before,
.tooltip.bs-tether-element-attached-left .tooltip-inner:before {
  border-right-color: #41506e; }

.total-wrap-alone {
  color: #41506e;
  font-weight: 500;
  font-size: 1.2rem; }

.info-label {
  font-size: 1.2rem; }

.unify-text * {
  font-family: inherit !important;
  font-size: 1.4rem; }

.admincls-1 {
  fill: #ee394e; }

.admincls-2 {
  fill: #fff; }

.admincls-3 {
  fill: #41506e; }

.avatar-cls-1 {
  fill: #e7eced; }

.avatar-cls-2 {
  fill: #556080; }

.broker-cls-1 {
  fill: #41506e; }

.broker-cls-2 {
  fill: #fff; }

.broker-cls-3 {
  fill: #ee394e; }

.burger-cls-1 {
  fill: #41506e; }

.charter-cls-1 {
  fill: #d8dde5; }

.charter-m-cls-1 {
  fill: #aab4c7; }

.day-trip-place-cls-1 {
  fill: #41506e; }

.day-trip-cls-1 {
  fill: #41506e; }

.dropdown-cls {
  fill: #444444; }

.mail-svg {
  fill: #3293f4; }

.modcls-1 {
  fill: #ee394e; }

.modcls-2 {
  fill: #fff; }

.modcls-3 {
  fill: #41506e; }

.new-cls-1 {
  fill: #ee394e; }

.night-trip-cls-1 {
  fill: #41506e; }

.ownercls-1 {
  fill: #ee394e; }

.ownercls-2 {
  fill: #fff; }

.ownercls-3 {
  fill: #41506e; }

.photo-cls-1 {
  fill: #41506e; }

.red-del-cls-1 {
  fill: #ee394e; }

.sale-g-cls-1 {
  fill: #d8dde5; }

.sale-cls-1 {
  fill: #41506e; }

.search-fill {
  fill: #15284b; }

.switch-cls-1 {
  fill: #e5e5e5; }

.switch-cls-2 {
  fill: #41506e; }

.tags-x {
  fill: #41506e; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-xs-1 {
  order: 1; }

.order-xs-2 {
  order: 2; }

.order-xs-3 {
  order: 3; }

.order-xs-4 {
  order: 4; }

.order-xs-5 {
  order: 5; }

@media (min-width: 576px) {
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; } }

@media (min-width: 768px) {
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; } }

@media (min-width: 992px) {
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; } }

@media (min-width: 1200px) {
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; } }

.row--32 .col--1, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--1, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--1 {
  flex: 0 0 3.125%;
  max-width: 3.125%; }

.row--32 .col--2, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--2, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--2 {
  flex: 0 0 6.25%;
  max-width: 6.25%; }

.row--32 .col--3, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--3, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--3 {
  flex: 0 0 9.375%;
  max-width: 9.375%; }

.row--32 .col--4, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--4, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--4 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.row--32 .col--5, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--5, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--5 {
  flex: 0 0 15.625%;
  max-width: 15.625%; }

.row--32 .col--6, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--6, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--6 {
  flex: 0 0 18.75%;
  max-width: 18.75%; }

.row--32 .col--7, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--7, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--7 {
  flex: 0 0 21.875%;
  max-width: 21.875%; }

.row--32 .col--8, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--8, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--8 {
  flex: 0 0 25%;
  max-width: 25%; }

.row--32 .col--9, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--9, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--9 {
  flex: 0 0 28.125%;
  max-width: 28.125%; }

.row--32 .col--10, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--10, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--10 {
  flex: 0 0 31.25%;
  max-width: 31.25%; }

.row--32 .col--11, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--11, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--11 {
  flex: 0 0 34.375%;
  max-width: 34.375%; }

.row--32 .col--12, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--12, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--12 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.row--32 .col--13, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--13, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--13 {
  flex: 0 0 40.625%;
  max-width: 40.625%; }

.row--32 .col--14, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--14, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--14 {
  flex: 0 0 43.75%;
  max-width: 43.75%; }

.row--32 .col--15, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--15, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--15 {
  flex: 0 0 46.875%;
  max-width: 46.875%; }

.row--32 .col--16, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--16, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--16 {
  flex: 0 0 50%;
  max-width: 50%; }

.row--32 .col--17, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--17, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--17 {
  flex: 0 0 53.125%;
  max-width: 53.125%; }

.row--32 .col--18, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--18, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--18 {
  flex: 0 0 56.25%;
  max-width: 56.25%; }

.row--32 .col--19, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--19, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--19 {
  flex: 0 0 59.375%;
  max-width: 59.375%; }

.row--32 .col--20, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--20, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--20 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.row--32 .col--21, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--21, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--21 {
  flex: 0 0 65.625%;
  max-width: 65.625%; }

.row--32 .col--22, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--22, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--22 {
  flex: 0 0 68.75%;
  max-width: 68.75%; }

.row--32 .col--23, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--23, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--23 {
  flex: 0 0 71.875%;
  max-width: 71.875%; }

.row--32 .col--24, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--24, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--24 {
  flex: 0 0 75%;
  max-width: 75%; }

.row--32 .col--25, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--25, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--25 {
  flex: 0 0 78.125%;
  max-width: 78.125%; }

.row--32 .col--26, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--26, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--26 {
  flex: 0 0 81.25%;
  max-width: 81.25%; }

.row--32 .col--27, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--27, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--27 {
  flex: 0 0 84.375%;
  max-width: 84.375%; }

.row--32 .col--28, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--28, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--28 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.row--32 .col--29, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--29, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--29 {
  flex: 0 0 90.625%;
  max-width: 90.625%; }

.row--32 .col--30, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--30, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--30 {
  flex: 0 0 93.75%;
  max-width: 93.75%; }

.row--32 .col--31, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--31, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--31 {
  flex: 0 0 96.875%;
  max-width: 96.875%; }

.row--32 .col--32, .table-wrapper .dashboard-wrapper .table .table-header > .row .col--32, .table-wrapper .dashboard-wrapper .table .table-body .table-row > .row .col--32 {
  flex: 0 0 100%;
  max-width: 100%; }

.disabled {
  pointer-events: none !important; }

.pos-absolute {
  position: absolute; }

.btn, #main .yacht-nav-link span {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  cursor: pointer;
  border-radius: 25px;
  font-size: 1.4rem;
  height: 40px;
  line-height: 25px;
  font-weight: 400;
  border: none;
  padding: 8px 16px; }
  .btn.btn-td-action, #main .yacht-nav-link span.btn-td-action {
    background: none;
    padding: 0;
    height: 24px;
    min-width: auto; }
    .btn.btn-td-action.comments.unread-comments, #main .yacht-nav-link span.btn-td-action.comments.unread-comments {
      position: relative; }
      .btn.btn-td-action.comments.unread-comments:before, #main .yacht-nav-link span.btn-td-action.comments.unread-comments:before {
        content: '';
        display: block;
        position: absolute;
        top: -3px;
        right: -3px;
        width: 10px;
        height: 10px;
        background: #ee394e;
        border-radius: 50%;
        border: 1px solid #f7f7f7; }
    .btn.btn-td-action svg path, #main .yacht-nav-link span.btn-td-action svg path {
      fill: #41506e !important; }
  .btn.btn-success, #main .yacht-nav-link span.btn-success {
    color: #fff;
    background: #5fcf80;
    min-width: 150px; }
  .btn.btn-info, #main .yacht-nav-link span.btn-info {
    background: #41506e;
    min-width: 150px; }
  .btn.btn-indication, #main .yacht-nav-link span.btn-indication {
    margin: -5px 0 0 5px;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-self: center;
    padding: 15px;
    background: #41506e;
    color: #fff; }
  .btn.btn-empty, #main .yacht-nav-link span.btn-empty {
    background: none;
    text-transform: initial;
    box-shadow: none !important;
    border: none; }
  .btn:focus, #main .yacht-nav-link span:focus {
    outline: none !important;
    box-shadow: none; }
  .btn.btn-main, #main .yacht-nav-link span.btn-main {
    background: #3293f4;
    color: #fff; }
    .btn.btn-main.grey, #main .yacht-nav-link span.btn-main.grey {
      background: #f7f7f7;
      color: #41506e; }
      .btn.btn-main.grey:hover, #main .yacht-nav-link span.btn-main.grey:hover {
        background: #41506e;
        color: white; }
  .btn.btn-tabs, #main .yacht-nav-link span.btn-tabs {
    margin-right: 24px; }
    .btn.btn-tabs.active, #main .yacht-nav-link span.btn-tabs.active {
      background: #41506e;
      color: white; }
  .btn.btn-action, #main .yacht-nav-link span.btn-action {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 8px; }
    .btn.btn-action svg, #main .yacht-nav-link span.btn-action svg {
      fill: #fff; }
      .btn.btn-action svg polygon, #main .yacht-nav-link span.btn-action svg polygon {
        fill: #fff; }
  .btn .btn-alabaster, #main .yacht-nav-link span .btn-alabaster {
    background-color: #f9f9f9;
    cursor: pointer;
    outline: none !important; }
  .btn.btn-blue, #main .yacht-nav-link span.btn-blue {
    background: #3293f4;
    min-width: 150px;
    color: #fff; }
    .btn.btn-blue.reverse, #main .yacht-nav-link span.btn-blue.reverse {
      padding: 0px;
      background: transparent;
      color: #3293f4;
      height: 20px;
      font-size: 1.2rem;
      text-transform: uppercase; }
      .btn.btn-blue.reverse > div svg, #main .yacht-nav-link span.btn-blue.reverse > div svg {
        fill: white;
        background: #3293f4;
        border-radius: 50%;
        padding: 2px; }
    .btn.btn-blue > div, #main .yacht-nav-link span.btn-blue > div {
      margin-right: 8px; }
      .btn.btn-blue > div > div, #main .yacht-nav-link span.btn-blue > div > div {
        line-height: 0; }
      .btn.btn-blue > div svg, #main .yacht-nav-link span.btn-blue > div svg {
        fill: #fff; }
  .btn.btn-primary, #main .yacht-nav-link span.btn-primary {
    background: #5fcf80;
    min-width: 150px; }
    .btn.btn-primary svg, #main .yacht-nav-link span.btn-primary svg {
      fill: #fff;
      padding-right: 8px; }
  .btn.btn-danger svg, #main .yacht-nav-link span.btn-danger svg {
    fill: #fff;
    padding-right: 8px; }
  .btn.btn-info svg, #main .yacht-nav-link span.btn-info svg {
    fill: #fff;
    padding-right: 8px; }
  .btn.btn-search, #main .yacht-nav-link span.btn-search {
    background: #41506e; }
  .btn.btn-offer, #main .yacht-nav-link span.btn-offer {
    background: #41506e;
    color: #fff !important;
    max-width: max-content;
    margin: 0 auto;
    padding: 0 14px 0px 6px;
    height: 32px;
    font-size: 1.2rem !important;
    text-transform: none; }
    .btn.btn-offer:hover, #main .yacht-nav-link span.btn-offer:hover {
      background: #3293f4; }
    .btn.btn-offer.disabled:hover, #main .yacht-nav-link span.btn-offer.disabled:hover {
      background: #41506e; }
    .btn.btn-offer.ghost, #main .yacht-nav-link span.btn-offer.ghost {
      background: transparent;
      color: #41506e !important;
      border: 1px solid #41506e; }
      .btn.btn-offer.ghost svg, #main .yacht-nav-link span.btn-offer.ghost svg {
        transition: all 0.2s ease-in-out;
        fill: #41506e;
        padding-top: 4px; }
      .btn.btn-offer.ghost:hover, #main .yacht-nav-link span.btn-offer.ghost:hover {
        background: #41506e;
        color: white !important; }
        .btn.btn-offer.ghost:hover svg, #main .yacht-nav-link span.btn-offer.ghost:hover svg {
          fill: white; }
  .btn.btn-clear, #main .yacht-nav-link span.btn-clear {
    color: #ee394e;
    font-size: 1.2rem; }
    .btn.btn-clear:hover, #main .yacht-nav-link span.btn-clear:hover {
      color: #41506e; }
  .btn.btn-secondary, #main .yacht-nav-link span.btn-secondary {
    background: none;
    color: #ee394e; }
  .btn.btn-secondary-blue, #main .yacht-nav-link span.btn-secondary-blue {
    background-color: transparent;
    color: #3293f4; }
  .btn.btn-favorite-action, #main .yacht-nav-link span.btn-favorite-action {
    height: auto;
    padding: 0;
    border-radius: 0;
    margin-right: 16px;
    padding-right: 16px;
    padding-left: 8px; }
    .btn.btn-favorite-action > div, #main .yacht-nav-link span.btn-favorite-action > div {
      border-radius: 100%;
      margin-right: 16px; }
    .btn.btn-favorite-action > span, #main .yacht-nav-link span.btn-favorite-action > span {
      font-size: 1.2rem;
      font-weight: 500;
      color: #aab4c7; }
    .btn.btn-favorite-action:hover > span, #main .yacht-nav-link span.btn-favorite-action:hover > span, .btn.btn-favorite-action.selected > span, #main .yacht-nav-link span.btn-favorite-action.selected > span {
      color: #3293f4; }
  .btn.btn-link, #main .yacht-nav-link span.btn-link {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.2rem; }
  .btn.btn-remove svg, #main .yacht-nav-link span.btn-remove svg {
    fill: #ee394e; }
  .btn.btn-red, #main .yacht-nav-link span.btn-red {
    background: #ee394e; }
  .btn.btn-smaller, #main .yacht-nav-link span.btn-smaller {
    line-height: 0;
    font-size: 1.2rem;
    padding: 4px 16px;
    height: 3.2rem; }

.transparent .custom-select-wrapper {
  background: transparent; }

.custom-select-wrapper {
  position: relative; }
  .custom-select-wrapper.transparent {
    background: transparent; }
  .custom-select-wrapper:after {
    content: '';
    position: absolute;
    background: url("../svgs/dropdown.svg");
    height: 16px;
    width: 16px;
    right: 8px;
    z-index: 0;
    top: 50%;
    z-index: 0;
    transform: translateY(-50%); }
  .custom-select-wrapper.react-select-wrapper:after {
    display: none; }
  .custom-select-wrapper.react-select-wrapper.disabled:not(.readonly) {
    opacity: 0.6;
    pointer-events: none; }
  .custom-select-wrapper.react-select-wrapper.error .Select .Select-control {
    border-bottom: 1px solid #ee394e; }
    .custom-select-wrapper.react-select-wrapper.error .Select .Select-control:hover {
      border-bottom: 1px solid #ee394e; }
  .custom-select-wrapper .custom-select {
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background: transparent;
    border: none;
    box-shadow: none !important;
    color: #aab4c7;
    border-bottom: 1px solid #d8dde5;
    font-size: 1.4rem;
    position: relative;
    z-index: 1;
    height: 32px !important;
    line-height: normal;
    padding: 0; }
    .custom-select-wrapper .custom-select[readonly] {
      pointer-events: none; }
  .custom-select-wrapper .custom-select-label {
    font-size: 1.2rem;
    color: #41506e;
    pointer-events: none;
    top: -12px;
    position: absolute;
    width: 100%; }
  .custom-select-wrapper.readonly:after {
    display: none; }
  .custom-select-wrapper .Select:after {
    display: none; }
  .custom-select-wrapper .Select .Select-control {
    border: none;
    border-bottom: 1px solid #d8dde5;
    height: 32px !important;
    border-radius: 0;
    background: transparent !important;
    color: #41506e; }
    .custom-select-wrapper .Select .Select-control .Select-placeholder {
      font-size: 1.2rem;
      color: #41506e;
      padding: 0;
      text-transform: uppercase; }
    .custom-select-wrapper .Select .Select-control .Select-arrow {
      border-color: transparent;
      border-width: 0;
      border-top: 5px solid #41506e;
      border-right: 5px solid transparent;
      border-bottom: 0 solid #41506e;
      border-left: 5px solid transparent;
      transition: 200ms ease;
      top: -2px; }
    .custom-select-wrapper .Select .Select-control:hover {
      border-bottom: 1px solid #41506e;
      box-shadow: none; }
    .custom-select-wrapper .Select .Select-control .Select-input {
      margin: 0;
      padding: 0;
      height: 31px; }
      .custom-select-wrapper .Select .Select-control .Select-input > input {
        color: #41506e;
        font-size: 1.4rem;
        padding: 0;
        height: 32px; }
    .custom-select-wrapper .Select .Select-control .Select-clear-zone {
      color: #41506e; }
      .custom-select-wrapper .Select .Select-control .Select-clear-zone:hover {
        color: #ee394e; }
    .custom-select-wrapper .Select .Select-control .Select-clear {
      font-weight: bold; }
    .custom-select-wrapper .Select .Select-control .Select-value {
      margin: 0;
      background: none;
      border: none;
      display: inline-flex;
      padding-left: 0; }
      .custom-select-wrapper .Select .Select-control .Select-value .Select-value-icon {
        width: 16px;
        height: 32px;
        order: 2;
        background: url("../svgs/tag-x.svg") #fff no-repeat center center;
        color: transparent;
        border: none;
        margin: 0 8px; }
      .custom-select-wrapper .Select .Select-control .Select-value .Select-value-label {
        font-size: 1.4rem;
        color: #41506e !important;
        padding: 0; }
  .custom-select-wrapper .Select .Select-menu-outer {
    border: 1px solid #d8dde5; }
  .custom-select-wrapper .Select .Select-option {
    font-size: 1.4rem;
    color: #41506e; }
  .custom-select-wrapper .Select .Select-noresults {
    font-size: 1.4rem;
    color: #41506e; }
  .custom-select-wrapper .Select.is-focused .Select-control {
    border-bottom: 1px solid #41506e;
    box-shadow: none; }
  .custom-select-wrapper .Select.is-disabled {
    pointer-events: none; }
    .custom-select-wrapper .Select.is-disabled .Select-arrow-zone {
      opacity: 0; }
    .custom-select-wrapper .Select.is-disabled > .Select-control {
      border-bottom: 0; }
    .custom-select-wrapper .Select.is-disabled .Select-placeholder {
      font-weight: 300; }
  .custom-select-wrapper .Select.is-open .Select-control .Select-arrow {
    border-width: 5px 5px 0 5px;
    transform: rotateX(180deg); }
  .custom-select-wrapper .Select-menu-outer .is-disabled.separator {
    border-bottom: 1px solid #d8dde5;
    padding: 0;
    margin: 0;
    padding-top: 0.5rem;
    margin: 0 1rem;
    margin-bottom: 0.5rem; }

.form-control {
  box-shadow: none !important;
  padding: 8px 16px; }

.field-outer-wrapper .filter-label {
  font-size: 1.2rem;
  color: #41506e;
  margin-bottom: 0.8rem; }

.field-outer-wrapper .float,
.field-outer-wrapper .top {
  display: none; }

.field-outer-wrapper .floating-label-wrapper {
  margin-bottom: 2.4rem !important; }
  .field-outer-wrapper .floating-label-wrapper .form-control {
    border: 1px solid #d8dde5;
    padding-left: 0.5rem;
    border-radius: 2px; }
    .field-outer-wrapper .floating-label-wrapper .form-control::placeholder {
      color: rgba(65, 80, 110, 0.5); }
    .field-outer-wrapper .floating-label-wrapper .form-control:not(:disabled):hover {
      border: 1px solid #d8dde5; }

.news-form-wrapper {
  padding-left: 56px; }
  .news-form-wrapper .redactor-wrap .redactor-label {
    border-bottom: 1px solid #d8dde5; }
  .news-form-wrapper .redactor-wrap .redactor-toolbar {
    display: none; }
  .news-form-wrapper .desc-header {
    font-size: 1.4rem;
    border: 1px solid #d8dde5;
    border-bottom: none;
    padding: 16px 12px;
    color: #41506e !important;
    font-weight: 500; }
  .news-form-wrapper .news-desc {
    width: 100%;
    outline: none !important;
    border: 1px solid #d8dde5;
    resize: none;
    padding: 8px 16px; }
  .news-form-wrapper .photo-upload-wrapper .photo-upload-label {
    color: #41506e;
    font-size: 12px; }
  .news-form-wrapper .photo-upload-wrapper .btn-alabaster {
    border-radius: 2rem;
    border: none;
    height: 4rem;
    color: #41506e;
    font-size: 1.4rem; }
  .news-form-wrapper .photo-upload-wrapper .btn-primary {
    font-weight: 300; }
  .news-form-wrapper .photo-upload-wrapper .btn-clear {
    font-size: 14px;
    background-color: transparent; }
  .news-form-wrapper .selected-image {
    padding: 32px 28px;
    background-color: #e5e8ed; }
  .news-form-wrapper .image-choice {
    background-color: #e5e8ed;
    padding: 16px 14px 17px;
    cursor: pointer; }
  .news-form-wrapper .image-active {
    border: 1px solid #3293f4; }

.photo-upload-modal .upload-panel.upload-box {
  width: auto; }

.photo-upload-modal .image-upload-wrapper {
  border-bottom: 1px solid #d8dde5; }

.column-direction-wrapper .checkboxes-wrapper {
  flex-direction: column; }

.menu-search-wrapper .filter-label {
  font-size: 1.6rem; }

.filter-divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #d8dde5;
  border-top: none; }

.floating-label-wrapper {
  position: relative;
  margin-bottom: 40px; }
  .floating-label-wrapper:first-child {
    margin-top: 24px; }
  .floating-label-wrapper .float {
    position: absolute;
    line-height: 1;
    opacity: 0;
    font-size: 1.2rem;
    color: #41506e;
    pointer-events: none;
    top: 100%;
    left: 0;
    text-transform: uppercase;
    -webkit-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -moz-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -ms-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -o-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67); }
    .floating-label-wrapper .float.error {
      top: auto;
      bottom: 0;
      color: #ee394e;
      font-size: 1rem; }
  .floating-label-wrapper .float.top {
    -webkit-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -moz-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -ms-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -o-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    top: -12px;
    opacity: 1; }
    .floating-label-wrapper .float.top.error {
      top: auto;
      bottom: -16px; }
  .floating-label-wrapper .form-control {
    box-shadow: none;
    height: 32px;
    font-size: 1.4rem;
    background: transparent;
    border: none;
    border-bottom: 1px solid #d8dde5;
    border-radius: 0;
    padding: 0;
    color: #41506e !important; }
    .floating-label-wrapper .form-control::-webkit-input-placeholder {
      color: #41506e;
      font-size: 12px;
      opacity: 1;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      font-style: normal; }
    .floating-label-wrapper .form-control:-moz-placeholder {
      color: #41506e;
      font-size: 12px;
      opacity: 1;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      font-style: normal; }
    .floating-label-wrapper .form-control::-moz-placeholder {
      color: #41506e;
      font-size: 12px;
      opacity: 1;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      font-style: normal; }
    .floating-label-wrapper .form-control:-ms-input-placeholder {
      color: #41506e;
      font-size: 12px;
      opacity: 1;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
      font-style: normal; }
    .floating-label-wrapper .form-control:-webkit-autofill, .floating-label-wrapper .form-control:-webkit-autofill:hover, .floating-label-wrapper .form-control:-webkit-autofill:focus, .floating-label-wrapper .form-control:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset; }
    .floating-label-wrapper .form-control:not(:disabled):not(:read-only):hover {
      border-bottom: 1px solid #41506e; }
    .floating-label-wrapper .form-control.error {
      border-bottom: 1px solid #ee394e; }
    .floating-label-wrapper .form-control[readonly] {
      border: none;
      cursor: default !important; }
      .floating-label-wrapper .form-control[readonly]::-webkit-input-placeholder {
        color: #41506e;
        font-size: 12px;
        opacity: 1;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .floating-label-wrapper .form-control[readonly]:-moz-placeholder {
        color: #41506e;
        font-size: 12px;
        opacity: 1;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .floating-label-wrapper .form-control[readonly]::-moz-placeholder {
        color: #41506e;
        font-size: 12px;
        opacity: 1;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .floating-label-wrapper .form-control[readonly]:-ms-input-placeholder {
        color: #41506e;
        font-size: 12px;
        opacity: 1;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
    .floating-label-wrapper .form-control.form-control-text {
      word-break: break-word;
      height: auto;
      min-height: 32px;
      padding: 4px 0px; }
  .floating-label-wrapper .form-control:focus ~ .floating-label {
    top: -8px;
    opacity: 1;
    visibility: visible; }
  .floating-label-wrapper.password-wrapper .btn-action {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
    padding: 0;
    height: 32px; }
    .floating-label-wrapper.password-wrapper .btn-action svg {
      fill: #41506e; }
  .floating-label-wrapper.disabled .custom-select-label {
    color: #d8dde5; }
  .floating-label-wrapper.disabled .form-control {
    color: #d8dde5 !important;
    border-color: #d8dde5; }

@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0; }
  25% {
    height: 0;
    width: 5px; }
  50% {
    height: 12px;
    width: 5px; } }

@-moz-keyframes check {
  0% {
    height: 0;
    width: 0; }
  25% {
    height: 0;
    width: 5px; }
  50% {
    height: 12px;
    width: 5px; } }

@-ms-keyframes check {
  0% {
    height: 0;
    width: 0; }
  25% {
    height: 0;
    width: 5px; }
  50% {
    height: 12px;
    width: 5px; } }

@keyframes check {
  0% {
    height: 0;
    width: 0; }
  25% {
    height: 0;
    width: 5px; }
  50% {
    height: 12px;
    width: 5px; } }

.radio-group-label {
  text-transform: uppercase;
  color: #41506e;
  font-size: 1.2rem;
  padding-top: 4px; }

.radio-wrapper {
  margin-bottom: 24px; }

.checkbox-wrapper {
  margin-bottom: 24px; }
  .checkbox-wrapper.align-select {
    margin-top: 1.1rem; }
  .checkbox-wrapper .checkbox {
    display: flex;
    margin: 0;
    cursor: pointer; }
    .checkbox-wrapper .checkbox label {
      color: #41506e;
      margin: 0;
      cursor: pointer; }
    .checkbox-wrapper .checkbox .checkmark-box {
      position: relative;
      font-size: 1.4rem;
      line-height: 16px; }
      .checkbox-wrapper .checkbox .checkmark-box:before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        cursor: pointer;
        border: 1px solid #d8dde5; }
      .checkbox-wrapper .checkbox .checkmark-box:after {
        transform-origin: left top;
        border-right: 2px solid transparent;
        border-top: 2px solid transparent;
        content: '';
        display: block;
        height: 12px;
        left: 2px;
        position: absolute;
        top: 9px;
        width: 5px;
        transform: scaleX(-1) rotate(135deg); }
    .checkbox-wrapper .checkbox:hover input:not(:checked) + .checkmark-box:after {
      opacity: 0.5; }
    .checkbox-wrapper .checkbox input {
      display: none; }
    .checkbox-wrapper .checkbox input:checked + .checkmark-box:before {
      background: #3293f4;
      border-color: #3293f4; }
    .checkbox-wrapper .checkbox input:checked + .checkmark-box:after {
      border-color: #fff;
      -webkit-animation: check 0.8s;
      -moz-animation: check 0.8s;
      -o-animation: check 0.8s;
      animation: check 0.8s; }
  .checkbox-wrapper.disabled .checkbox label {
    cursor: default; }
  .checkbox-wrapper.disabled .checkbox .checkmark-box:before {
    cursor: default; }
  .checkbox-wrapper.customer-cats {
    height: 32px; }
    .checkbox-wrapper.customer-cats .customer-cat {
      background: none;
      box-shadow: inset 0px 0px 1px 1px #d8dde5;
      color: #d8dde5 !important;
      margin: 0px 8px; }
    .checkbox-wrapper.customer-cats .active .customer-cat {
      color: #fff !important;
      box-shadow: none; }
      .checkbox-wrapper.customer-cats .active .customer-cat.customer-sales {
        background: #41506e; }
      .checkbox-wrapper.customer-cats .active .customer-cat.customer-chartering {
        background: #aab4c7; }
  .checkbox-wrapper.customer-favourite {
    height: 24px; }

.form-label {
  margin-bottom: 16px; }

.radio label {
  cursor: pointer;
  padding-left: 0; }
  .radio label.readonly {
    cursor: default; }
  .radio label span {
    font-size: 1.4rem;
    line-height: 16px;
    color: #aab4c7;
    align-items: center;
    position: relative; }
    .radio label span:before {
      content: '';
      border-radius: 100%;
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      display: inline-block;
      color: #41506e;
      border: 1px solid #d8dde5;
      background: white; }
    .radio label span:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 100%;
      top: 5px;
      left: 5px; }
  .radio label input[type='radio'] {
    display: none; }
    .radio label input[type='radio']:checked + span {
      color: #41506e; }
      .radio label input[type='radio']:checked + span:before {
        background: #3293f4;
        border-color: #3293f4; }

.gender-radios .radio > label svg {
  fill: #d8dde5; }
  .gender-radios .radio > label svg.active.male {
    fill: #80cfd4; }
  .gender-radios .radio > label svg.active.female {
    fill: #e992b5; }

.gender-radios .radio > label + label {
  margin-left: 16px; }

.toggle-wrapper.inline-toggle .toggle .toggle-label {
  padding-right: 16px; }

.toggle-wrapper .toggle-label {
  line-height: 1;
  font-size: 1.2rem;
  color: #41506e;
  pointer-events: none;
  margin-bottom: 16px; }

.toggle-wrapper label {
  cursor: pointer;
  padding-left: 0;
  margin: 0; }
  .toggle-wrapper label > span {
    height: 16px;
    width: 32px;
    border-radius: 60px;
    background-color: #aab4c7;
    transition: background 0.2s;
    position: relative; }
    .toggle-wrapper label > span:before, .toggle-wrapper label > span:after {
      display: inline-block;
      position: absolute;
      content: ''; }
    .toggle-wrapper label > span:before {
      left: 2px;
      right: 2px;
      background-color: #aab4c7;
      top: 0;
      bottom: 0;
      border-radius: 60px;
      transition: background 0.2s; }
    .toggle-wrapper label > span:after {
      top: 0;
      left: 0;
      bottom: 0;
      background-color: #fff;
      border: 2px solid #aab4c7;
      width: 16px;
      border-radius: 52px;
      transition: margin 0.2s, background 0.2s; }
  .toggle-wrapper label input[type='checkbox'] {
    display: none; }
    .toggle-wrapper label input[type='checkbox']:checked + span {
      background-color: #5fcf80; }
      .toggle-wrapper label input[type='checkbox']:checked + span:before {
        background-color: #5fcf80; }
      .toggle-wrapper label input[type='checkbox']:checked + span:after {
        background-color: #fff;
        margin-left: 16px;
        border: 2px solid #5fcf80; }

.list-form {
  transition: all 0.6s ease; }
  .list-form.hidden-form {
    visibility: hidden; }
  .list-form .floating-label-wrapper {
    margin-bottom: 64px; }
    .list-form .floating-label-wrapper .dropdown-wrapper {
      z-index: 101;
      position: absolute;
      background: #fff;
      width: 100%;
      border: 1px solid #d1d1d1;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      border-top: 0;
      padding: 8px 8px 8px 16px;
      margin: -16px 0 0;
      top: 48px; }
      .list-form .floating-label-wrapper .dropdown-wrapper ul > li {
        margin: 8px 0px 8px 0px; }
        .list-form .floating-label-wrapper .dropdown-wrapper ul > li > a {
          width: 100%;
          color: #41506e; }
  .list-form .addresses-wrapper .multiple-wrap .col,
  .list-form .email-wrap .multiple-wrap .col {
    background: #f9f9f9; }
  .list-form .item-wrapper .col-auto {
    position: absolute;
    right: -24px; }
    .list-form .item-wrapper .col-auto > button {
      padding: 0; }
  .list-form .coordinates-wrapper {
    position: relative; }
    .list-form .coordinates-wrapper .floating-label-wrapper .form-control {
      padding-right: 8%; }
      .list-form .coordinates-wrapper .floating-label-wrapper .form-control:hover:not(.error) {
        border-bottom: 1px solid #41506e; }
      .list-form .coordinates-wrapper .floating-label-wrapper .form-control[readonly] {
        border-bottom: 1px solid #d8dde5; }
    .list-form .coordinates-wrapper .extra-options-info {
      position: absolute;
      font-size: 1.2rem;
      bottom: -16px; }
    .list-form .coordinates-wrapper .extra-options-wrap {
      position: absolute;
      right: -6px;
      width: 8%;
      top: 8px;
      font-size: 0.9rem;
      color: #41506e; }
      .list-form .coordinates-wrapper .extra-options-wrap > .form-group {
        margin: 0; }
  .list-form .name-wrapper {
    position: relative; }
    .list-form .name-wrapper .floating-label-wrapper label.float {
      display: none; }
    .list-form .name-wrapper .floating-label-wrapper .form-control {
      padding-right: 30%;
      font-size: 2rem; }
      .list-form .name-wrapper .floating-label-wrapper .form-control::-webkit-input-placeholder {
        color: #aab4c7;
        font-size: 2rem;
        opacity: 1;
        font-weight: 100;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .list-form .name-wrapper .floating-label-wrapper .form-control:-moz-placeholder {
        color: #aab4c7;
        font-size: 2rem;
        opacity: 1;
        font-weight: 100;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .list-form .name-wrapper .floating-label-wrapper .form-control::-moz-placeholder {
        color: #aab4c7;
        font-size: 2rem;
        opacity: 1;
        font-weight: 100;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .list-form .name-wrapper .floating-label-wrapper .form-control:-ms-input-placeholder {
        color: #aab4c7;
        font-size: 2rem;
        opacity: 1;
        font-weight: 100;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .list-form .name-wrapper .floating-label-wrapper .form-control:hover:not(.error) {
        border-bottom: 1px solid #41506e; }
      .list-form .name-wrapper .floating-label-wrapper .form-control[readonly] {
        border-bottom: 1px solid #d8dde5; }
    .list-form .name-wrapper .extra-options-wrap {
      position: absolute;
      right: 0;
      width: 30%;
      top: 0;
      height: 32px; }
      .list-form .name-wrapper .extra-options-wrap.no-float {
        position: static;
        width: auto;
        margin-top: 24px; }
      .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view {
        width: 15%; }
        .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .gender-radios .radio > label {
          margin-left: 0 !important; }
        .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios {
          line-height: normal; }
          .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios.customer-sales, .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios.customer-chartering {
            background: none; }
          .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios:first-child {
            margin-right: 8px; }
          .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios .form-check-label {
            width: 100%;
            height: 100%; }
          .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios span {
            color: white;
            justify-content: center;
            height: 100%;
            border-radius: 100%;
            background: #aab4c7; }
            .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios span:before, .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios span:after {
              display: none; }
          .list-form .name-wrapper .extra-options-wrap.customer-options-wrap.view .customer-radios input:checked + span {
            background: #41506e; }
      .list-form .name-wrapper .extra-options-wrap > .form-group {
        margin: 0; }
    .list-form .name-wrapper .btn-edit {
      position: absolute;
      right: -64px;
      top: -8px; }
    .list-form .name-wrapper.no-actions .form-control {
      padding: 0; }
    .list-form .name-wrapper.hide-disabled .gender-radios .radio.male input[value='female'] {
      display: none; }
      .list-form .name-wrapper.hide-disabled .gender-radios .radio.male input[value='female'] + div {
        display: none; }
    .list-form .name-wrapper.hide-disabled .gender-radios .radio.female input[value='male'] {
      display: none; }
      .list-form .name-wrapper.hide-disabled .gender-radios .radio.female input[value='male'] + div {
        display: none; }
    .list-form .name-wrapper.hide-disabled .customer-cats label:not(.active) {
      display: none; }

.tags-wrapper {
  margin-bottom: 4rem; }
  .tags-wrapper .tags-list {
    position: relative; }
    .tags-wrapper .tags-list .tag-label {
      line-height: 1;
      font-size: 1.2rem;
      color: #41506e;
      pointer-events: none;
      width: 100%;
      position: absolute;
      top: -12px;
      left: 0; }
    .tags-wrapper .tags-list > ul {
      flex-wrap: wrap;
      border-bottom: 1px solid #d8dde5;
      margin: 0; }
      t .tags-wrapper .tags-list > ul:hover {
        border-bottom: 1px solid #41506e; }
      .tags-wrapper .tags-list > ul .form-control {
        padding: 0;
        height: 32px;
        background: none;
        border: none;
        font-size: 1.4rem;
        line-height: 32px; }
      .tags-wrapper .tags-list > ul > li:not(:last-child) {
        padding-right: 24px;
        position: relative; }
        .tags-wrapper .tags-list > ul > li:not(:last-child) .form-control {
          cursor: default; }
        .tags-wrapper .tags-list > ul > li:not(:last-child) .btn-remove {
          position: absolute;
          right: 8px;
          top: 0;
          padding: 0;
          width: 16px;
          height: 32px;
          background: url("../svgs/tag-x.svg") no-repeat center center; }
      .tags-wrapper .tags-list > ul .tag-input .form-control::-webkit-input-placeholder {
        color: #41506e;
        font-size: 14px;
        opacity: 1;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .tags-wrapper .tags-list > ul .tag-input .form-control:-moz-placeholder {
        color: #41506e;
        font-size: 14px;
        opacity: 1;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .tags-wrapper .tags-list > ul .tag-input .form-control::-moz-placeholder {
        color: #41506e;
        font-size: 14px;
        opacity: 1;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .tags-wrapper .tags-list > ul .tag-input .form-control:-ms-input-placeholder {
        color: #41506e;
        font-size: 14px;
        opacity: 1;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .tags-wrapper .tags-list > ul.readonly {
        border: none; }
        .tags-wrapper .tags-list > ul.readonly > li {
          border: 1px solid #d8dde5;
          padding: 0px 8px;
          text-align: center;
          margin: 8px 8px 8px 0px; }
          .tags-wrapper .tags-list > ul.readonly > li .form-control {
            cursor: default;
            text-align: center; }
        .tags-wrapper .tags-list > ul.readonly + .tag-tab-info {
          display: none; }
    .tags-wrapper .tags-list .tag-tab-info {
      font-size: 1.2rem;
      position: absolute;
      bottom: -16px; }
    .tags-wrapper .tags-list .tag-tab-info-error {
      font-size: 1.2rem;
      position: absolute;
      bottom: -16px;
      color: #ee394e; }
  .tags-wrapper.f-width .tag-input {
    width: 100%; }

.results-list {
  position: absolute;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
  max-height: 248px;
  overflow-y: auto; }
  .results-list > li > a,
  .results-list > li > span {
    padding: 8px 16px;
    color: #41506e;
    display: block;
    font-size: 1.4rem;
    cursor: pointer; }
    .results-list > li > a:hover,
    .results-list > li > span:hover {
      background: #f9f9f9; }

.brokers-wrap .floating-label-wrapper,
.companies-wrap .floating-label-wrapper {
  margin-top: 0; }

.brokers-wrap .brokers-list > li,
.brokers-wrap .companies-list > li,
.brokers-wrap .destinations-multiple-list > li,
.companies-wrap .brokers-list > li,
.companies-wrap .companies-list > li,
.companies-wrap .destinations-multiple-list > li,
.destinations-multiple-wrap .brokers-list > li,
.destinations-multiple-wrap .companies-list > li,
.destinations-multiple-wrap .destinations-multiple-list > li {
  position: relative; }
  .brokers-wrap .brokers-list > li .floating-label-wrapper,
  .brokers-wrap .companies-list > li .floating-label-wrapper,
  .brokers-wrap .destinations-multiple-list > li .floating-label-wrapper,
  .companies-wrap .brokers-list > li .floating-label-wrapper,
  .companies-wrap .companies-list > li .floating-label-wrapper,
  .companies-wrap .destinations-multiple-list > li .floating-label-wrapper,
  .destinations-multiple-wrap .brokers-list > li .floating-label-wrapper,
  .destinations-multiple-wrap .companies-list > li .floating-label-wrapper,
  .destinations-multiple-wrap .destinations-multiple-list > li .floating-label-wrapper {
    margin-bottom: 40px; }
  .brokers-wrap .brokers-list > li .btn-remove,
  .brokers-wrap .companies-list > li .btn-remove,
  .brokers-wrap .destinations-multiple-list > li .btn-remove,
  .companies-wrap .brokers-list > li .btn-remove,
  .companies-wrap .companies-list > li .btn-remove,
  .companies-wrap .destinations-multiple-list > li .btn-remove,
  .destinations-multiple-wrap .brokers-list > li .btn-remove,
  .destinations-multiple-wrap .companies-list > li .btn-remove,
  .destinations-multiple-wrap .destinations-multiple-list > li .btn-remove {
    position: absolute;
    bottom: 0;
    right: -16px;
    padding: 0;
    align-items: center; }
    .brokers-wrap .brokers-list > li .btn-remove span,
    .brokers-wrap .companies-list > li .btn-remove span,
    .brokers-wrap .destinations-multiple-list > li .btn-remove span,
    .companies-wrap .brokers-list > li .btn-remove span,
    .companies-wrap .companies-list > li .btn-remove span,
    .companies-wrap .destinations-multiple-list > li .btn-remove span,
    .destinations-multiple-wrap .brokers-list > li .btn-remove span,
    .destinations-multiple-wrap .companies-list > li .btn-remove span,
    .destinations-multiple-wrap .destinations-multiple-list > li .btn-remove span {
      padding-left: 8px;
      font-size: 1.2rem;
      color: #41506e; }
    .brokers-wrap .brokers-list > li .btn-remove svg,
    .brokers-wrap .companies-list > li .btn-remove svg,
    .brokers-wrap .destinations-multiple-list > li .btn-remove svg,
    .companies-wrap .brokers-list > li .btn-remove svg,
    .companies-wrap .companies-list > li .btn-remove svg,
    .companies-wrap .destinations-multiple-list > li .btn-remove svg,
    .destinations-multiple-wrap .brokers-list > li .btn-remove svg,
    .destinations-multiple-wrap .companies-list > li .btn-remove svg,
    .destinations-multiple-wrap .destinations-multiple-list > li .btn-remove svg {
      fill: #ee394e; }
  .brokers-wrap .brokers-list > li .results-list,
  .brokers-wrap .companies-list > li .results-list,
  .brokers-wrap .destinations-multiple-list > li .results-list,
  .companies-wrap .brokers-list > li .results-list,
  .companies-wrap .companies-list > li .results-list,
  .companies-wrap .destinations-multiple-list > li .results-list,
  .destinations-multiple-wrap .brokers-list > li .results-list,
  .destinations-multiple-wrap .companies-list > li .results-list,
  .destinations-multiple-wrap .destinations-multiple-list > li .results-list {
    top: 32px; }

.brokers-wrap .destinations-multiple-list > li .btn-remove,
.companies-wrap .destinations-multiple-list > li .btn-remove,
.destinations-multiple-wrap .destinations-multiple-list > li .btn-remove {
  position: initial; }

.multiple-wrap .floating-label-wrapper {
  margin-bottom: 24px; }

label.upload-label {
  margin-bottom: 16px;
  font-size: 1.2rem;
  color: #41506e; }

label.or-label {
  text-transform: initial !important;
  margin-top: 24px; }

.label.filter-label {
  font-size: 1.2rem;
  color: #41506e;
  margin-bottom: 24px; }

.upload-panel {
  position: relative; }
  .upload-panel .upload-single-image > img {
    width: 128px; }
  .upload-panel label.upload-input {
    border-radius: 25px;
    padding: 8px 24px;
    background: #f9f9f9;
    display: inline-block;
    width: 100%;
    cursor: pointer; }
    .upload-panel label.upload-input .input-wrap {
      color: #41506e;
      font-size: 1.2rem; }
      .upload-panel label.upload-input .input-wrap div {
        font-size: 1.2rem;
        line-height: 1.6rem;
        height: 16px; }
        .upload-panel label.upload-input .input-wrap div.upload-icon-photo svg {
          fill: #41506e; }
    .upload-panel label.upload-input input {
      display: none; }
  .upload-panel .description {
    opacity: 0.4;
    font-size: 1.2rem;
    line-height: 1.6rem; }
  .upload-panel .upload-single-image .btn, .upload-panel .upload-single-image #main .yacht-nav-link span, #main .yacht-nav-link .upload-panel .upload-single-image span {
    color: #41506e;
    font-size: 1.2rem;
    width: 104px; }
    .upload-panel .upload-single-image .btn div, .upload-panel .upload-single-image #main .yacht-nav-link span div, #main .yacht-nav-link .upload-panel .upload-single-image span div {
      height: 16px; }
  .upload-panel .upload-single-image > a.close-button {
    cursor: pointer;
    color: #ee394e;
    border-radius: 50px;
    background: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    display: inline-block;
    line-height: 0;
    padding: 12px 7px;
    bottom: -56px;
    position: relative;
    right: 16px;
    top: 32px;
    -webkit-box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21); }
  .upload-panel.upload-box {
    width: 248px;
    height: 248px;
    border: 1px dashed #d8dde5; }
    .upload-panel.upload-box .description {
      position: absolute;
      width: 100%;
      bottom: -24px; }
    @media (max-width: 991.98px) {
      .upload-panel.upload-box .description {
        bottom: -35px; } }
    .upload-panel.upload-box.modal-box {
      width: 100%;
      height: 160px;
      border: 2px dashed #d8dde5; }
      .upload-panel.upload-box.modal-box .upload-input .input-wrap .upload-icon-photo {
        flex-direction: initial; }
        .upload-panel.upload-box.modal-box .upload-input .input-wrap .upload-icon-photo .ml-1 {
          margin-left: 16px !important; }
    .upload-panel.upload-box .upload-single-image {
      width: 100%;
      height: 100%;
      flex-direction: column;
      padding: 16px; }
      .upload-panel.upload-box .upload-single-image img {
        width: 100%;
        object-fit: cover;
        height: calc(100% - 24px); }
      .upload-panel.upload-box .upload-single-image .btn, .upload-panel.upload-box .upload-single-image #main .yacht-nav-link span, #main .yacht-nav-link .upload-panel.upload-box .upload-single-image span {
        margin-top: auto; }
    .upload-panel.upload-box .upload-input {
      width: 100%;
      height: 100%;
      background: none;
      border-radius: 0; }
      .upload-panel.upload-box .upload-input .input-wrap {
        width: 100%; }
        .upload-panel.upload-box .upload-input .input-wrap .upload-icon-photo {
          flex-direction: column; }
          .upload-panel.upload-box .upload-input .input-wrap .upload-icon-photo .d-inline-block {
            margin: 0 !important; }
  .upload-panel.round-photo {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    border-color: #aab4c7; }
    .upload-panel.round-photo .label-text,
    .upload-panel.round-photo .btn-empty {
      position: absolute;
      left: 16rem;
      display: block;
      width: 100%; }
    .upload-panel.round-photo .upload-input {
      margin-bottom: 0; }
    .upload-panel.round-photo .upload-single-image {
      padding: 0;
      position: relative; }
      .upload-panel.round-photo .upload-single-image img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 100%; }

.video-upload-button .upload-input {
  width: initial;
  padding: 8px; }

.grouped-panel-wrapper > :first-child {
  margin-top: 3.2rem; }

.grouped-panel-wrapper .grouped-panel {
  padding: 16px;
  background: #f9f9f9; }
  .grouped-panel-wrapper .grouped-panel .datepicker-wrapper .btn.btn-date, .grouped-panel-wrapper .grouped-panel .datepicker-wrapper #main .yacht-nav-link span.btn-date, #main .yacht-nav-link .grouped-panel-wrapper .grouped-panel .datepicker-wrapper span.btn-date {
    background: #d8dde5; }

.react-tel-input {
  position: relative; }
  .react-tel-input .ad {
    background-position: -16px 0; }
  .react-tel-input .ae {
    background-position: -32px 0; }
  .react-tel-input .af {
    background-position: -48px 0; }
  .react-tel-input .ag {
    background-position: -64px 0; }
  .react-tel-input .ai {
    background-position: -80px 0; }
  .react-tel-input .al {
    background-position: -96px 0; }
  .react-tel-input .am {
    background-position: -112px 0; }
  .react-tel-input .ao {
    background-position: -128px 0; }
  .react-tel-input .ar {
    background-position: -144px 0; }
  .react-tel-input .as {
    background-position: -160px 0; }
  .react-tel-input .at {
    background-position: -176px 0; }
  .react-tel-input .au {
    background-position: -192px 0; }
  .react-tel-input .aw {
    background-position: -208px 0; }
  .react-tel-input .az {
    background-position: -224px 0; }
  .react-tel-input .ba {
    background-position: -240px 0; }
  .react-tel-input .bb {
    background-position: 0 -11px; }
  .react-tel-input .bd {
    background-position: -16px -11px; }
  .react-tel-input .be {
    background-position: -32px -11px; }
  .react-tel-input .bf {
    background-position: -48px -11px; }
  .react-tel-input .bg {
    background-position: -64px -11px; }
  .react-tel-input .bh {
    background-position: -80px -11px; }
  .react-tel-input .bi {
    background-position: -96px -11px; }
  .react-tel-input .bj {
    background-position: -112px -11px; }
  .react-tel-input .bm {
    background-position: -128px -11px; }
  .react-tel-input .bn {
    background-position: -144px -11px; }
  .react-tel-input .bo {
    background-position: -160px -11px; }
  .react-tel-input .br {
    background-position: -176px -11px; }
  .react-tel-input .bs {
    background-position: -192px -11px; }
  .react-tel-input .bt {
    background-position: -208px -11px; }
  .react-tel-input .bw {
    background-position: -224px -11px; }
  .react-tel-input .by {
    background-position: -240px -11px; }
  .react-tel-input .bz {
    background-position: 0 -22px; }
  .react-tel-input .ca {
    background-position: -16px -22px; }
  .react-tel-input .cd {
    background-position: -32px -22px; }
  .react-tel-input .cf {
    background-position: -48px -22px; }
  .react-tel-input .cg {
    background-position: -64px -22px; }
  .react-tel-input .ch {
    background-position: -80px -22px; }
  .react-tel-input .ci {
    background-position: -96px -22px; }
  .react-tel-input .ck {
    background-position: -112px -22px; }
  .react-tel-input .cl {
    background-position: -128px -22px; }
  .react-tel-input .cm {
    background-position: -144px -22px; }
  .react-tel-input .cn {
    background-position: -160px -22px; }
  .react-tel-input .co {
    background-position: -176px -22px; }
  .react-tel-input .cr {
    background-position: -192px -22px; }
  .react-tel-input .cu {
    background-position: -208px -22px; }
  .react-tel-input .cv {
    background-position: -224px -22px; }
  .react-tel-input .cw {
    background-position: -240px -22px; }
  .react-tel-input .cy {
    background-position: 0 -33px; }
  .react-tel-input .cz {
    background-position: -16px -33px; }
  .react-tel-input .de {
    background-position: -32px -33px; }
  .react-tel-input .dj {
    background-position: -48px -33px; }
  .react-tel-input .dk {
    background-position: -64px -33px; }
  .react-tel-input .dm {
    background-position: -80px -33px; }
  .react-tel-input .do {
    background-position: -96px -33px; }
  .react-tel-input .dz {
    background-position: -112px -33px; }
  .react-tel-input .ec {
    background-position: -128px -33px; }
  .react-tel-input .ee {
    background-position: -144px -33px; }
  .react-tel-input .eg {
    background-position: -160px -33px; }
  .react-tel-input .er {
    background-position: -176px -33px; }
  .react-tel-input .es {
    background-position: -192px -33px; }
  .react-tel-input .et {
    background-position: -208px -33px; }
  .react-tel-input .fi {
    background-position: -224px -33px; }
  .react-tel-input .fj {
    background-position: -240px -33px; }
  .react-tel-input .fk {
    background-position: 0 -44px; }
  .react-tel-input .fm {
    background-position: -16px -44px; }
  .react-tel-input .fo {
    background-position: -32px -44px; }
  .react-tel-input .fr,
  .react-tel-input .bl,
  .react-tel-input .mf {
    background-position: -48px -44px; }
  .react-tel-input .ga {
    background-position: -64px -44px; }
  .react-tel-input .gb {
    background-position: -80px -44px; }
  .react-tel-input .gd {
    background-position: -96px -44px; }
  .react-tel-input .ge {
    background-position: -112px -44px; }
  .react-tel-input .gf {
    background-position: -128px -44px; }
  .react-tel-input .gh {
    background-position: -144px -44px; }
  .react-tel-input .gi {
    background-position: -160px -44px; }
  .react-tel-input .gl {
    background-position: -176px -44px; }
  .react-tel-input .gm {
    background-position: -192px -44px; }
  .react-tel-input .gn {
    background-position: -208px -44px; }
  .react-tel-input .gp {
    background-position: -224px -44px; }
  .react-tel-input .gq {
    background-position: -240px -44px; }
  .react-tel-input .gr {
    background-position: 0 -55px; }
  .react-tel-input .gt {
    background-position: -16px -55px; }
  .react-tel-input .gu {
    background-position: -32px -55px; }
  .react-tel-input .gw {
    background-position: -48px -55px; }
  .react-tel-input .gy {
    background-position: -64px -55px; }
  .react-tel-input .hk {
    background-position: -80px -55px; }
  .react-tel-input .hn {
    background-position: -96px -55px; }
  .react-tel-input .hr {
    background-position: -112px -55px; }
  .react-tel-input .ht {
    background-position: -128px -55px; }
  .react-tel-input .hu {
    background-position: -144px -55px; }
  .react-tel-input .id {
    background-position: -160px -55px; }
  .react-tel-input .ie {
    background-position: -176px -55px; }
  .react-tel-input .il {
    background-position: -192px -55px; }
  .react-tel-input .in {
    background-position: -208px -55px; }
  .react-tel-input .io {
    background-position: -224px -55px; }
  .react-tel-input .iq {
    background-position: -240px -55px; }
  .react-tel-input .ir {
    background-position: 0 -66px; }
  .react-tel-input .is {
    background-position: -16px -66px; }
  .react-tel-input .it {
    background-position: -32px -66px; }
  .react-tel-input .jm {
    background-position: -48px -66px; }
  .react-tel-input .jo {
    background-position: -64px -66px; }
  .react-tel-input .jp {
    background-position: -80px -66px; }
  .react-tel-input .ke {
    background-position: -96px -66px; }
  .react-tel-input .kg {
    background-position: -112px -66px; }
  .react-tel-input .kh {
    background-position: -128px -66px; }
  .react-tel-input .ki {
    background-position: -144px -66px; }
  .react-tel-input .km {
    background-position: -160px -66px; }
  .react-tel-input .kn {
    background-position: -176px -66px; }
  .react-tel-input .kp {
    background-position: -192px -66px; }
  .react-tel-input .kr {
    background-position: -208px -66px; }
  .react-tel-input .kw {
    background-position: -224px -66px; }
  .react-tel-input .ky {
    background-position: -240px -66px; }
  .react-tel-input .kz {
    background-position: 0 -77px; }
  .react-tel-input .la {
    background-position: -16px -77px; }
  .react-tel-input .lb {
    background-position: -32px -77px; }
  .react-tel-input .lc {
    background-position: -48px -77px; }
  .react-tel-input .li {
    background-position: -64px -77px; }
  .react-tel-input .lk {
    background-position: -80px -77px; }
  .react-tel-input .lr {
    background-position: -96px -77px; }
  .react-tel-input .ls {
    background-position: -112px -77px; }
  .react-tel-input .lt {
    background-position: -128px -77px; }
  .react-tel-input .lu {
    background-position: -144px -77px; }
  .react-tel-input .lv {
    background-position: -160px -77px; }
  .react-tel-input .ly {
    background-position: -176px -77px; }
  .react-tel-input .ma {
    background-position: -192px -77px; }
  .react-tel-input .mc {
    background-position: -208px -77px; }
  .react-tel-input .md {
    background-position: -224px -77px; }
  .react-tel-input .me {
    background-position: -112px -154px;
    height: 12px; }
  .react-tel-input .mg {
    background-position: 0 -88px; }
  .react-tel-input .mh {
    background-position: -16px -88px; }
  .react-tel-input .mk {
    background-position: -32px -88px; }
  .react-tel-input .ml {
    background-position: -48px -88px; }
  .react-tel-input .mm {
    background-position: -64px -88px; }
  .react-tel-input .mn {
    background-position: -80px -88px; }
  .react-tel-input .mo {
    background-position: -96px -88px; }
  .react-tel-input .mp {
    background-position: -112px -88px; }
  .react-tel-input .mq {
    background-position: -128px -88px; }
  .react-tel-input .mr {
    background-position: -144px -88px; }
  .react-tel-input .ms {
    background-position: -160px -88px; }
  .react-tel-input .mt {
    background-position: -176px -88px; }
  .react-tel-input .mu {
    background-position: -192px -88px; }
  .react-tel-input .mv {
    background-position: -208px -88px; }
  .react-tel-input .mw {
    background-position: -224px -88px; }
  .react-tel-input .mx {
    background-position: -240px -88px; }
  .react-tel-input .my {
    background-position: 0 -99px; }
  .react-tel-input .mz {
    background-position: -16px -99px; }
  .react-tel-input .na {
    background-position: -32px -99px; }
  .react-tel-input .nc {
    background-position: -48px -99px; }
  .react-tel-input .ne {
    background-position: -64px -99px; }
  .react-tel-input .nf {
    background-position: -80px -99px; }
  .react-tel-input .ng {
    background-position: -96px -99px; }
  .react-tel-input .ni {
    background-position: -112px -99px; }
  .react-tel-input .nl,
  .react-tel-input .bq {
    background-position: -128px -99px; }
  .react-tel-input .no {
    background-position: -144px -99px; }
  .react-tel-input .np {
    background-position: -160px -99px; }
  .react-tel-input .nr {
    background-position: -176px -99px; }
  .react-tel-input .nu {
    background-position: -192px -99px; }
  .react-tel-input .nz {
    background-position: -208px -99px; }
  .react-tel-input .om {
    background-position: -224px -99px; }
  .react-tel-input .pa {
    background-position: -240px -99px; }
  .react-tel-input .pe {
    background-position: 0 -110px; }
  .react-tel-input .pf {
    background-position: -16px -110px; }
  .react-tel-input .pg {
    background-position: -32px -110px; }
  .react-tel-input .ph {
    background-position: -48px -110px; }
  .react-tel-input .pk {
    background-position: -64px -110px; }
  .react-tel-input .pl {
    background-position: -80px -110px; }
  .react-tel-input .pm {
    background-position: -96px -110px; }
  .react-tel-input .pr {
    background-position: -112px -110px; }
  .react-tel-input .ps {
    background-position: -128px -110px; }
  .react-tel-input .pt {
    background-position: -144px -110px; }
  .react-tel-input .pw {
    background-position: -160px -110px; }
  .react-tel-input .py {
    background-position: -176px -110px; }
  .react-tel-input .qa {
    background-position: -192px -110px; }
  .react-tel-input .re {
    background-position: -208px -110px; }
  .react-tel-input .ro {
    background-position: -224px -110px; }
  .react-tel-input .rs {
    background-position: -240px -110px; }
  .react-tel-input .ru {
    background-position: 0 -121px; }
  .react-tel-input .rw {
    background-position: -16px -121px; }
  .react-tel-input .sa {
    background-position: -32px -121px; }
  .react-tel-input .sb {
    background-position: -48px -121px; }
  .react-tel-input .sc {
    background-position: -64px -121px; }
  .react-tel-input .sd {
    background-position: -80px -121px; }
  .react-tel-input .se {
    background-position: -96px -121px; }
  .react-tel-input .sg {
    background-position: -112px -121px; }
  .react-tel-input .sh {
    background-position: -128px -121px; }
  .react-tel-input .si {
    background-position: -144px -121px; }
  .react-tel-input .sk {
    background-position: -160px -121px; }
  .react-tel-input .sl {
    background-position: -176px -121px; }
  .react-tel-input .sm {
    background-position: -192px -121px; }
  .react-tel-input .sn {
    background-position: -208px -121px; }
  .react-tel-input .so {
    background-position: -224px -121px; }
  .react-tel-input .sr {
    background-position: -240px -121px; }
  .react-tel-input .ss {
    background-position: 0 -132px; }
  .react-tel-input .st {
    background-position: -16px -132px; }
  .react-tel-input .sv {
    background-position: -32px -132px; }
  .react-tel-input .sx {
    background-position: -48px -132px; }
  .react-tel-input .sy {
    background-position: -64px -132px; }
  .react-tel-input .sz {
    background-position: -80px -132px; }
  .react-tel-input .tc {
    background-position: -96px -132px; }
  .react-tel-input .td {
    background-position: -112px -132px; }
  .react-tel-input .tg {
    background-position: -128px -132px; }
  .react-tel-input .th {
    background-position: -144px -132px; }
  .react-tel-input .tj {
    background-position: -160px -132px; }
  .react-tel-input .tk {
    background-position: -176px -132px; }
  .react-tel-input .tl {
    background-position: -192px -132px; }
  .react-tel-input .tm {
    background-position: -208px -132px; }
  .react-tel-input .tn {
    background-position: -224px -132px; }
  .react-tel-input .to {
    background-position: -240px -132px; }
  .react-tel-input .tr {
    background-position: 0 -143px; }
  .react-tel-input .tt {
    background-position: -16px -143px; }
  .react-tel-input .tv {
    background-position: -32px -143px; }
  .react-tel-input .tw {
    background-position: -48px -143px; }
  .react-tel-input .tz {
    background-position: -64px -143px; }
  .react-tel-input .ua {
    background-position: -80px -143px; }
  .react-tel-input .ug {
    background-position: -96px -143px; }
  .react-tel-input .us {
    background-position: -112px -143px; }
  .react-tel-input .uy {
    background-position: -128px -143px; }
  .react-tel-input .uz {
    background-position: -144px -143px; }
  .react-tel-input .va {
    background-position: -160px -143px; }
  .react-tel-input .vc {
    background-position: -176px -143px; }
  .react-tel-input .ve {
    background-position: -192px -143px; }
  .react-tel-input .vg {
    background-position: -208px -143px; }
  .react-tel-input .vi {
    background-position: -224px -143px; }
  .react-tel-input .vn {
    background-position: -240px -143px; }
  .react-tel-input .vu {
    background-position: 0 -154px; }
  .react-tel-input .wf {
    background-position: -16px -154px; }
  .react-tel-input .ws {
    background-position: -32px -154px; }
  .react-tel-input .ye {
    background-position: -48px -154px; }
  .react-tel-input .za {
    background-position: -64px -154px; }
  .react-tel-input .zm {
    background-position: -80px -154px; }
  .react-tel-input .zw {
    background-position: -96px -154px; }
  .react-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .react-tel-input .hide {
    display: none; }
  .react-tel-input .v-hide {
    visibility: hidden; }
  .react-tel-input input[type='text'],
  .react-tel-input input[type='tel'] {
    margin-left: 40px;
    width: calc(100% - 40px); }
    .react-tel-input input[type='text'].invalid-number,
    .react-tel-input input[type='tel'].invalid-number {
      border-color: #ee394e; }
  .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0; }
    .react-tel-input .flag-dropdown.open-dropdown {
      border-bottom: 0; }
      .react-tel-input .flag-dropdown.open-dropdown .selected-flag {
        border-radius: 3px 0 0 0; }
    .react-tel-input .flag-dropdown:hover {
      cursor: pointer; }
  .react-tel-input input[disabled] + .flag-dropdown:hover {
    cursor: default; }
    .react-tel-input input[disabled] + .flag-dropdown:hover .selected-flag {
      background-color: transparent; }
  .react-tel-input .selected-flag {
    z-index: 13;
    position: relative;
    width: 30px;
    height: 32px;
    border-bottom: 1px solid #d8dde5; }
    .react-tel-input .selected-flag .flag {
      position: absolute;
      top: 50%;
      margin-top: -5px;
      background-image: url(../img/flags.png) !important; }
    .react-tel-input .selected-flag .arrow {
      position: relative;
      top: 50%;
      margin-top: -2px;
      left: 20px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555; }
      .react-tel-input .selected-flag .arrow.up {
        border-top: none;
        border-bottom: 4px solid #555; }
  .react-tel-input .country-list {
    list-style: none;
    position: absolute;
    z-index: 2;
    padding: 0;
    background-color: white;
    border: 1px solid #ccc;
    width: 400px;
    max-height: 198px;
    overflow-y: scroll;
    top: 31px; }
    .react-tel-input .country-list .flag {
      display: inline-block;
      background-image: url(../img/flags.png) !important; }
    .react-tel-input .country-list .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ccc;
      display: none; }
    .react-tel-input .country-list .country {
      padding: 8px 10px;
      font-size: 1.2rem;
      color: #41506e;
      display: flex;
      align-items: center; }
      .react-tel-input .country-list .country .dial-code {
        color: #41506e;
        font-weight: 400;
        font-size: inherit; }
      .react-tel-input .country-list .country:hover {
        background-color: #f2f9fc; }
      .react-tel-input .country-list .country.highlight {
        background-color: #f5faff; }
    .react-tel-input .country-list .flag {
      margin-right: 6px; }
    .react-tel-input .country-list .country-name {
      margin-right: 6px;
      font-size: inherit; }
  .react-tel-input.readonly .selected-flag {
    border: none;
    right: 8px;
    pointer-events: none; }
    .react-tel-input.readonly .selected-flag .arrow {
      display: none; }
  .react-tel-input.readonly .flag-dropdown:hover {
    cursor: default; }
  .react-tel-input.readonly input[type='text'],
  .react-tel-input.readonly input[type='tel'] {
    border: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    pointer-events: none; }
  .react-tel-input.editPhone input[type='tel'] {
    border-bottom: 1px solid #d8dde5;
    border-top: 0;
    border-left: 0;
    border-right: 0; }
  .react-tel-input.editPhone .selected-flag {
    border: none;
    outline: 0px;
    right: 8px;
    width: 30px; }
    .react-tel-input.editPhone .selected-flag:hover {
      cursor: pointer; }
  .react-tel-input.editPhone .flag-dropdown:hover {
    cursor: pointer; }

.save-help-label {
  padding-left: 8px; }

.select-help-text {
  font-size: 1.2rem;
  font-weight: 400;
  position: absolute;
  bottom: -16px; }

.togglable-select {
  cursor: pointer; }

.toggle-select-close {
  position: absolute;
  top: 50%;
  right: -0.9rem;
  transform: translateY(-50%); }

.wants_to_buy_fields .checkbox-wrapper {
  width: 25%; }

.wants_to_buy_fields .filter-label {
  color: #41506e;
  font-size: 1.2rem; }

.wants_to_buy_fields .floating-label-wrapper {
  margin-bottom: 3.2rem; }

.wants_to_buy_fields .Select-value-icon {
  background-color: transparent !important; }

.wants_to_buy_fields .trigger-collapse button {
  background-color: #f9f9f9 !important; }

.yacht-name-exists-warning {
  margin-bottom: 30px; }
  .yacht-name-exists-warning.warning {
    margin-top: -37px;
    margin-bottom: 10px;
    color: #c9302c; }

.yacht-name-exists-warning-content {
  margin-bottom: 8px;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem; }
  .yacht-name-exists-warning-content.label {
    color: #41506e;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 20px; }
  .yacht-name-exists-warning-content.link {
    font-weight: 400; }

.yacht-curation-level {
  position: relative;
  top: -33px; }

@media screen and (max-width: 1024px) {
  .upload-panel label.upload-input .input-wrap div {
    height: auto; } }

.table-actions {
  background: #f7f7f7;
  margin-bottom: 24px;
  border-radius: 25px; }
  .table-actions .action-label {
    padding: 0px 16px;
    color: #3293f4;
    font-size: 1.4rem; }
  .table-actions .extra-table-actions {
    margin-left: auto; }
    .table-actions .extra-table-actions .btn-primary {
      min-width: 164px;
      padding: 8px 24px; }

.table .col-date {
  width: 10rem; }

.table .table-header {
  margin-bottom: 0;
  border-bottom: 1px solid #d8dde5;
  padding: 0px 16px; }
  .table .table-header .header-col {
    padding: 16px 8px; }
    .table .table-header .header-col > span {
      font-size: 1.1rem;
      color: #41506e;
      text-transform: uppercase; }
    .table .table-header .header-col.sort-icon > span {
      cursor: pointer;
      position: relative;
      padding-right: 16px; }
      .table .table-header .header-col.sort-icon > span:before {
        content: '';
        width: 8px;
        height: 8px;
        border: 1px solid #41506e;
        border-top: 0;
        border-right: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(-45deg); }
    .table .table-header .header-col.sort-icon.asc > span:before {
      top: 4px;
      transform: rotate(-225deg); }

.table .table-body .table-row {
  border-bottom: 1px solid #d8dde5; }
  .table .table-body .table-row.popup-wrap {
    position: relative;
    cursor: default; }
    .table .table-body .table-row.popup-wrap.company {
      padding-right: 40px; }
    .table .table-body .table-row.popup-wrap div.popup-image {
      z-index: 100;
      position: absolute;
      top: -40px;
      left: 210px;
      visibility: hidden; }
      .table .table-body .table-row.popup-wrap div.popup-image img {
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        height: 100px;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0px 3px 8px #d8dde5; }
        .table .table-body .table-row.popup-wrap div.popup-image img.opacity {
          visibility: visible;
          opacity: 1; }
  .table .table-body .table-row > div {
    color: #41506e;
    font-size: 1.4rem;
    word-wrap: break-word;
    align-items: center;
    padding: 0px 16px;
    height: 5.6rem; }
    .table .table-body .table-row > div div,
    .table .table-body .table-row > div span,
    .table .table-body .table-row > div a {
      color: inherit;
      font-size: inherit;
      line-height: 1.5; }
    .table .table-body .table-row > div a {
      color: #3293f4; }
    .table .table-body .table-row > div > [class^='col-'],
    .table .table-body .table-row > div [class*=' col-'] {
      padding: 0px 8px; }
    .table .table-body .table-row > div .td-toggle .toggle-wrapper {
      margin: 0; }
      .table .table-body .table-row > div .td-toggle .toggle-wrapper .toggle-label {
        display: none; }

.table .pagination-wrapper .total-wrap {
  color: #5fcf80;
  font-weight: 500;
  font-size: 1.4rem; }
  .table .pagination-wrapper .total-wrap.border {
    padding-right: 24px;
    margin-right: 16px;
    border-right: 1px solid #d8dde5; }

.table .pagination-wrapper .checkbox-wrapper {
  margin: 0px 16px; }

.table .pagination-wrapper.disabled-prev .pagination > li:first-child > a {
  color: #aab4c7;
  cursor: default; }

.table .pagination-wrapper.disabled-next .pagination > li:last-child > a {
  color: #aab4c7;
  cursor: default; }

.table .pagination-wrapper .pagination {
  margin: 0; }
  .table .pagination-wrapper .pagination > li:not(:first-child):not(:last-child):not(.active) {
    display: none; }
  .table .pagination-wrapper .pagination > li:first-child > a, .table .pagination-wrapper .pagination > li:last-child > a {
    font-size: 2.4rem;
    line-height: 1.6rem;
    display: inline-block; }
  .table .pagination-wrapper .pagination > li > a {
    border: none;
    background: none;
    padding: 0px 8px;
    color: #41506e;
    outline: none;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
    display: inline-block; }
  .table .pagination-wrapper .pagination > li.disabled > a {
    color: #aab4c7; }
  .table .pagination-wrapper .pagination > li.active > a {
    color: #41506e; }

.table .pagination-wrapper .pagination-label {
  padding: 0px 8px;
  font-weight: 500;
  font-size: 1.2rem;
  color: #aab4c7; }

.table .pagination-wrapper.pagination-header {
  margin-bottom: 8px; }

.table .pagination-wrapper.pagination-footer {
  margin-top: 32px; }
  .table .pagination-wrapper.pagination-footer li:first-child > a, .table .pagination-wrapper.pagination-footer li:last-child > a {
    font-weight: 300;
    line-height: 1.2rem;
    height: 100%; }
  .table .pagination-wrapper.pagination-footer li:not(:first-child):not(:last-child):not(.active) {
    display: initial; }
  .table .pagination-wrapper.pagination-footer li a {
    padding: 8px 0;
    border: 1px solid #d8dde5;
    border-radius: 0;
    margin-left: 4px;
    min-width: 3.2rem;
    text-align: center; }
  .table .pagination-wrapper.pagination-footer li.active a {
    color: white;
    background: #41506e;
    border-color: #41506e; }

.table .pagination-wrapper .table-pagination-wrapper .custom-select-wrapper .custom-select {
  color: #41506e;
  width: 64px;
  font-size: 1.2rem;
  padding: 8px 16px;
  height: 40px;
  border: 1px solid #d8dde5;
  border-radius: 25px; }

.table .pagination-wrapper .extra-filter-wrapper .floating-label-wrapper {
  margin: 0; }
  .table .pagination-wrapper .extra-filter-wrapper .floating-label-wrapper .custom-select-label {
    display: none; }

.table-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem; }
  .table-wrapper.dnd-table {
    color: #aab4c7; }
    .table-wrapper.dnd-table .table-header {
      padding-left: 0;
      padding-right: 0; }
      .table-wrapper.dnd-table .table-header .table-title {
        font-size: 1.2rem;
        font-weight: 500; }
      .table-wrapper.dnd-table .table-header .table-title--dark {
        color: #41506e; }
    .table-wrapper.dnd-table .table-row {
      border-bottom: 1px solid #d8dde5; }
    .table-wrapper.dnd-table .news-title,
    .table-wrapper.dnd-table .news-created-date,
    .table-wrapper.dnd-table .news-author {
      font-size: 14px;
      line-height: 16px; }
    .table-wrapper.dnd-table .is-dragging {
      background-color: #fff;
      border: 1px solid #d8dde5;
      box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1); }
      .table-wrapper.dnd-table .is-dragging .floating-label-wrapper {
        bottom: 8px; }
  .table-wrapper .table-header {
    padding: 10px 24px 8px;
    border-bottom: 1px solid #d8dde5;
    border-radius: 0.4rem; }
    .table-wrapper .table-header .table-title {
      color: #fff;
      font-size: 1.4rem; }
    .table-wrapper .table-header .table-subtitle {
      font-size: 1.2rem;
      font-weight: 300; }
  .table-wrapper .dashboard-wrapper {
    border-right: 1px solid rgba(0, 0, 0, 0.1); }
    .table-wrapper .dashboard-wrapper::-webkit-scrollbar {
      display: none; }
    .table-wrapper .dashboard-wrapper .table .table-header {
      border: none;
      font-size: 1.2rem;
      font-weight: 300;
      padding: 0 1.6rem 1.6rem; }
      .table-wrapper .dashboard-wrapper .table .table-header .header-col {
        padding: 0; }
        .table-wrapper .dashboard-wrapper .table .table-header .header-col span {
          color: #747474;
          font-weight: 300;
          font-size: 1.1rem; }
        .table-wrapper .dashboard-wrapper .table .table-header .header-col.sort-icon span:before {
          border-color: #747474; }
    .table-wrapper .dashboard-wrapper .table .table-body {
      height: 20rem;
      overflow-y: overlay;
      overflow-x: hidden; }
      .table-wrapper .dashboard-wrapper .table .table-body .table-row {
        margin-bottom: 0.8rem;
        border: none; }
        .table-wrapper .dashboard-wrapper .table .table-body .table-row:last-child {
          margin-bottom: 0; }
        .table-wrapper .dashboard-wrapper .table .table-body .table-row div {
          height: auto;
          color: #293246; }
          .table-wrapper .dashboard-wrapper .table .table-body .table-row div.gray-field {
            color: #848484; }
        .table-wrapper .dashboard-wrapper .table .table-body .table-row div[class*='col-'] {
          padding: 0; }
        .table-wrapper .dashboard-wrapper .table .table-body .table-row .yacht-image {
          object-fit: cover; }

.table-td-actions {
  margin: 0; }
  .table-td-actions > li {
    padding: 0px 8px; }
    .table-td-actions > li .btn-td-action svg {
      fill: #41506e; }

.btn-td-action {
  white-space: normal; }

.dashboard-widget-wrapper .table-wrapper {
  border: none; }
  .dashboard-widget-wrapper .table-wrapper .table-body-wrap {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0; }

.dashboard-widget-wrapper .widget > .table-header {
  background-color: #41506e;
  border-radius: 0.4rem 0.4rem 0 0;
  padding: 0.8rem 2.4rem; }
  .dashboard-widget-wrapper .widget > .table-header .table-subtitle {
    color: #aab4c7; }
  .dashboard-widget-wrapper .widget > .table-header .table-title {
    font-size: 1.2rem; }

.dashboard-widget-wrapper .widget.table-wrapper .load-more-wrapper {
  margin: 0.8rem 0; }
  .dashboard-widget-wrapper .widget.table-wrapper .load-more-wrapper .load-more-btn {
    color: #3293f4;
    cursor: pointer;
    font-size: 1.4rem; }
    .dashboard-widget-wrapper .widget.table-wrapper .load-more-wrapper .load-more-btn.disabled {
      color: #aab4c7;
      pointer-events: none; }

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .table-wrapper .dashboard-wrapper .table .table-body .table-row div {
    font-size: 1rem !important; }
  .table-wrapper .dashboard-wrapper .table .table-header .header-col span {
    font-size: 1rem; }
  .slider-wrapper.slider-width {
    left: 205px !important;
    width: calc(100% - 205px) !important; } }

/* Gallery Photos Start */
.gallery-photos .gallery-photos-prewrap .gallery-label {
  font-size: 1.2rem;
  color: #41506e;
  margin-bottom: 16px; }

.gallery-photos .gallery-photos-prewrap .optional {
  color: #aab4c7;
  font-size: 1.2rem;
  padding-left: 16px; }

.gallery-photos .gallery-photos-prewrap .gallery-wrap {
  margin-right: 16px;
  white-space: nowrap; }
  .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 1.6rem; }
    .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul::-webkit-scrollbar {
      height: 6px; }
    .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      -webkit-border-radius: 10px;
      border-radius: 10px; }
    .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #dedede;
      -webkit-box-shadow: inset 0 0 6px #dedede; }
    .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li {
      position: relative;
      margin-right: 4rem; }
      .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li .image {
        height: 12rem;
        width: 18rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center; }
      .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li .gen-type-wrap {
        position: relative; }
        .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li .gen-type-wrap.file-type-wrap .image {
          width: 9rem;
          background-size: auto 100%; }
        .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li .gen-type-wrap.file-type-wrap a .img-label {
          position: initial;
          width: 10rem;
          padding: 0;
          margin-top: 1.6rem;
          line-height: 1.4rem; }
        .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li .gen-type-wrap.file-type-wrap-image .image {
          width: 18rem;
          background-size: auto 100%; }
        .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li .gen-type-wrap.file-type-wrap-image a .img-label {
          position: initial;
          width: 18rem;
          padding: 0;
          margin-top: 1.6rem;
          line-height: 1.4rem; }
      .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li a.close-button {
        cursor: pointer;
        color: #ee394e;
        border-radius: 50px;
        background: #fff;
        font-size: 1.6rem;
        font-weight: 500;
        display: inline-block;
        line-height: 0;
        padding: 12px 7px;
        position: absolute;
        top: 10.4rem;
        right: -8px;
        -webkit-box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21);
        box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21); }
      .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > ul > li a .img-label {
        display: block;
        font-size: 1.2rem;
        position: absolute;
        bottom: 6px;
        left: 0;
        padding: 0 24px;
        width: 124px;
        word-wrap: break-word;
        white-space: normal;
        color: #41506e; }
  .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > div {
    padding-bottom: 1.6rem;
    flex-wrap: wrap; }
    .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > div::-webkit-scrollbar {
      height: 6px; }
    .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > div::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      -webkit-border-radius: 10px;
      border-radius: 10px; }
    .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > div::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: #dedede;
      -webkit-box-shadow: inset 0 0 6px #dedede; }
    .gallery-photos .gallery-photos-prewrap .gallery-wrap .gallery-list > div > div {
      margin: 0rem 4rem 2.4rem 0px;
      z-index: 0; }

div.gallery-list-item {
  z-index: 10; }

.gallery-image-box {
  position: relative;
  cursor: pointer; }
  .gallery-image-box .image {
    height: 12rem;
    width: 18rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .gallery-image-box.gen-type-wrap {
    position: relative; }
    .gallery-image-box.gen-type-wrap.file-type-wrap .image {
      width: 9rem;
      background-size: auto 100%; }
    .gallery-image-box.gen-type-wrap.file-type-wrap a .img-label {
      position: initial;
      width: 10rem;
      padding: 0;
      margin-top: 1.6rem;
      line-height: 1.4rem; }
    .gallery-image-box.gen-type-wrap.file-type-wrap-image .image {
      width: 18rem; }
    .gallery-image-box.gen-type-wrap.file-type-wrap-image a .img-label {
      position: initial;
      width: 18rem;
      padding: 0;
      margin-top: 1.6rem;
      line-height: 1.4rem; }
  .gallery-image-box a.close-button {
    cursor: pointer;
    color: #ee394e;
    border-radius: 50px;
    background: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    display: inline-block;
    line-height: 0;
    padding: 12px 7px;
    position: absolute;
    top: 10.4rem;
    right: -8px;
    -webkit-box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21); }
  .gallery-image-box a.downloadImage-button {
    cursor: pointer;
    height: 10px;
    display: inline-block;
    line-height: 0;
    padding: 12px 7px;
    position: absolute;
    top: 8.3rem;
    right: 1px;
    -webkit-box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21); }
    .gallery-image-box a.downloadImage-button svg {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background-color: rgba(255, 255, 255, 0.8);
      border-style: solid;
      border-color: transparent; }
      .gallery-image-box a.downloadImage-button svg polygon {
        fill: #3293f4; }
  .gallery-image-box a .img-label {
    display: block;
    font-size: 1.2rem;
    position: absolute;
    bottom: 6px;
    left: 0;
    padding: 0 24px;
    width: 124px;
    word-wrap: break-word;
    white-space: normal;
    color: #41506e; }

.gallery-image-box-with-selection {
  position: relative;
  cursor: pointer;
  margin-right: -26px; }
  .gallery-image-box-with-selection .image {
    height: 80px;
    width: 128px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .gallery-image-box-with-selection.gen-type-wrap {
    position: relative; }
    .gallery-image-box-with-selection.gen-type-wrap.file-type-wrap .image {
      width: 9rem;
      background-size: auto 100%; }
    .gallery-image-box-with-selection.gen-type-wrap.file-type-wrap a .img-label {
      position: initial;
      width: 128px;
      padding: 0;
      margin-top: 1.6rem;
      line-height: 1.4rem; }
    .gallery-image-box-with-selection.gen-type-wrap.file-type-wrap-image .image {
      width: 128px; }
    .gallery-image-box-with-selection.gen-type-wrap.file-type-wrap-image a .img-label {
      position: initial;
      width: 128px;
      padding: 0;
      margin-top: 1.6rem;
      line-height: 1.4rem; }
  .gallery-image-box-with-selection a.close-button {
    cursor: pointer;
    color: #ee394e;
    border-radius: 50px;
    background: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    display: inline-block;
    line-height: 0;
    padding: 12px 7px;
    position: absolute;
    top: 6.4rem;
    right: -6px;
    -webkit-box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21); }
  .gallery-image-box-with-selection a.downloadImage-button {
    cursor: pointer;
    height: 10px;
    display: inline-block;
    line-height: 0;
    padding: 12px 7px;
    position: absolute;
    top: 8.3rem;
    right: 1px;
    -webkit-box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.21); }
    .gallery-image-box-with-selection a.downloadImage-button svg {
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background-color: rgba(255, 255, 255, 0.8);
      border-style: solid;
      border-color: transparent; }
      .gallery-image-box-with-selection a.downloadImage-button svg polygon {
        fill: #3293f4; }
  .gallery-image-box-with-selection a .img-label {
    display: block;
    font-size: 1.2rem;
    position: absolute;
    bottom: 6px;
    left: 0;
    padding: 0 24px;
    width: 124px;
    word-wrap: break-word;
    white-space: normal;
    color: #41506e; }

/* Gallery Photos End */
/* Collapse Start */
.email-selector {
  z-index: 2000;
  color: black; }

.collapse-wrap {
  position: relative;
  line-height: 2.4rem;
  margin-bottom: 0; }
  .collapse-wrap .btn-title {
    height: initial;
    z-index: 1; }
  .collapse-wrap.just-line::before {
    width: 100%; }
  .collapse-wrap::before {
    content: '';
    position: absolute;
    width: 98%;
    left: 0;
    top: 11px;
    height: 1px;
    z-index: 0;
    background-image: linear-gradient(to right, #aab4c7 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 11px 2px;
    background-repeat: repeat-x;
    opacity: 0.6; }
  .collapse-wrap .collapse-label {
    background: #fff;
    padding-right: 8px;
    color: #aab4c7;
    font-size: 1.2rem;
    line-height: 1.6rem; }
    .collapse-wrap .collapse-label:hover {
      color: #41506e !important; }
    .collapse-wrap .collapse-label.grey {
      background: #f9f9f9; }
  .collapse-wrap .trigger-collapse {
    position: relative;
    right: 0px;
    top: 0px; }
    .collapse-wrap .trigger-collapse > button {
      display: inline-flex;
      background: #fff;
      width: 22px;
      height: 22px;
      justify-content: center;
      align-items: center;
      border: 1px solid #aab4c7;
      border-radius: 0px; }
      .collapse-wrap .trigger-collapse > button:hover {
        cursor: pointer;
        border-color: #41506e !important; }
        .collapse-wrap .trigger-collapse > button:hover > span {
          color: #41506e !important; }
      .collapse-wrap .trigger-collapse > button:focus {
        outline: none; }
      .collapse-wrap .trigger-collapse > button > span {
        color: #aab4c7;
        font-weight: 300; }
      .collapse-wrap .trigger-collapse > button.grey {
        background: #f9f9f9; }

.collapse-wrapper p {
  font-size: 1.4rem;
  color: #41506e;
  line-height: 1.4; }

.collapse-wrapper.opened > .collapse-wrap:before {
  background-image: linear-gradient(to right, #41506e 50%, rgba(255, 255, 255, 0) 0%) !important; }

.collapse-wrapper.opened > .collapse-wrap .collapse-label {
  color: #41506e !important; }

.collapse-wrapper.opened > .collapse-wrap .trigger-collapse > button {
  border: 1px solid #41506e !important; }
  .collapse-wrapper.opened > .collapse-wrap .trigger-collapse > button > span {
    color: #41506e !important; }

/* Collapse End */
/* Label Seperator Start */
.label-seperator {
  margin-bottom: 40px; }
  .label-seperator .seperator-title {
    background: #fff;
    padding-right: 16px;
    color: #41506e;
    font-size: 1.2rem; }
    .label-seperator .seperator-title::before {
      content: '';
      position: absolute;
      width: 100%;
      top: 8px;
      height: 1px;
      background: #ebeef2;
      z-index: -1; }

/* Label Seperator End */
/* BoxTabs Start */
.box-tabs-wrapper .box-tab {
  height: 17rem;
  border-radius: 0;
  color: #41506e;
  background-color: white;
  border: 1px solid #d9dde5;
  position: relative;
  overflow: hidden; }
  .box-tabs-wrapper .box-tab:hover .tab-action,
  .box-tabs-wrapper .box-tab:hover .tab-title {
    color: #fff !important; }
  .box-tabs-wrapper .box-tab.grey {
    background-color: white; }
  .box-tabs-wrapper .box-tab .tab-text {
    font-size: 2.4rem;
    text-align: center;
    width: 100%; }
  .box-tabs-wrapper .box-tab .tab-action,
  .box-tabs-wrapper .box-tab .tab-title {
    display: block;
    font-weight: 100;
    font-size: 2.4rem;
    line-height: 2rem;
    color: #41506e;
    white-space: normal; }
  .box-tabs-wrapper .box-tab .tab-subtitle {
    font-size: 1.3rem;
    font-weight: 800; }
  .box-tabs-wrapper .box-tab .tab-image-wrapper {
    position: absolute;
    bottom: -3px; }

/* BoxTabes End */
/* Label Seperator Start */
.label-seperator {
  margin-bottom: 40px; }
  .label-seperator .seperator-title {
    background: #fff;
    padding-right: 16px;
    color: #41506e;
    font-size: 1.2rem; }
    .label-seperator .seperator-title::before {
      content: '';
      position: absolute;
      width: 100%;
      top: 8px;
      height: 1px;
      background: #ebeef2;
      z-index: -1; }

/* Label Seperator End */
/* BoxTabs Start */
.box-tabs-wrapper .box-tab {
  height: 17rem;
  border-radius: 0;
  color: #41506e;
  background-color: white;
  border: 1px solid #d9dde5;
  position: relative;
  overflow: hidden;
  align-items: center; }
  .box-tabs-wrapper .box-tab:hover {
    border-color: transparent;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.2); }
  .box-tabs-wrapper .box-tab.grey {
    background-color: white; }
  .box-tabs-wrapper .box-tab .tab-text {
    font-size: 2.4rem; }
  .box-tabs-wrapper .box-tab .tab-title {
    display: block;
    font-weight: 300;
    font-size: 2rem;
    padding-bottom: 0.8rem; }
  .box-tabs-wrapper .box-tab .tab-subtitle {
    display: block;
    font-size: 1.4rem;
    font-weight: 500; }
  .box-tabs-wrapper .box-tab .tab-image-wrapper {
    position: absolute;
    bottom: -3px; }
  .box-tabs-wrapper .box-tab.inactive .tab-text {
    color: #aab4c7;
    padding: 0;
    margin: 0 auto;
    align-self: center;
    font-size: 1.6rem; }
  .box-tabs-wrapper .box-tab.inactive:hover {
    box-shadow: none;
    border-color: #d9dde5;
    background-color: white !important; }
    .box-tabs-wrapper .box-tab.inactive:hover > .tab-text {
      color: #aab4c7; }

.slider-wrapper {
  height: 152px;
  background-color: #fff;
  border-top: 1px solid #e5e8ed;
  left: 88px;
  width: calc(100% - 88px);
  transition: bottom 0.3s ease-in-out; }
  .slider-wrapper.ribbon-hidden {
    bottom: -132px; }
  .slider-wrapper .Select-arrow {
    margin-left: 8px;
    border-color: #41506e transparent transparent; }
    .slider-wrapper .Select-arrow.rotate {
      transform: rotateX(180deg);
      margin-bottom: 2px; }
  .slider-wrapper .btn.btn-tabs, .slider-wrapper #main .yacht-nav-link span.btn-tabs, #main .yacht-nav-link .slider-wrapper span.btn-tabs {
    background-color: #fff;
    color: #41506e;
    position: absolute;
    top: -21px; }
  .slider-wrapper .newsfeed-label-wrapper {
    position: relative;
    top: 24px; }
  .slider-wrapper.slider-width {
    left: 288px;
    width: calc(100% - 288px); }
  .slider-wrapper .slick-slider button {
    display: none !important; }
  .slider-wrapper .slick-slider ul {
    display: none !important; }
  .slider-wrapper .ribbon-item {
    color: #41506e !important;
    max-height: 104px;
    border-right: 2px solid #edc624;
    word-wrap: break-word; }
    .slider-wrapper .ribbon-item .ribbon-img-wrapper {
      position: relative; }
      .slider-wrapper .ribbon-item .ribbon-img-wrapper .ribbon-img-label {
        z-index: 10;
        width: 64px;
        height: 24px;
        background: #c4aa46;
        /* The Fallback */
        background: rgba(196, 170, 70, 0.8);
        position: absolute;
        left: 2px;
        top: 0;
        font-weight: 600;
        font-size: 1.4rem; }
    .slider-wrapper .ribbon-item .ribbon-item-title {
      margin-bottom: 0.4rem; }
    .slider-wrapper .ribbon-item .ribbon-item-img {
      object-fit: cover; }
    .slider-wrapper .ribbon-item .ribbon-item-desc {
      font-size: 1.2rem;
      line-height: 1.9rem; }

@media (max-width: 1199.98px) {
  .box-tabs-wrapper .box-tab {
    justify-content: flex-start; }
    .box-tabs-wrapper .box-tab .tab-text {
      padding-top: 1rem;
      padding-left: 0; } }

@media (max-width: 1199.98px) {
  .box-tab-outer {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important; } }

/* BoxTabes End */
.redactor-wrap.error .redactor-toolbar,
.redactor-wrap.error .redactor-layer {
  border-color: #ee394e; }

.redactor-wrap.error .error {
  font-size: 1rem;
  text-transform: uppercase;
  color: #ee394e;
  margin-bottom: 7px; }

.redactor-wrap .redactor-toolbar {
  box-shadow: none;
  border: 1px solid #d8dde5; }
  .redactor-wrap .redactor-toolbar li a {
    color: #41506e;
    background: none !important; }
    .redactor-wrap .redactor-toolbar li a:hover {
      background: none;
      color: #3293f4; }
    .redactor-wrap .redactor-toolbar li a.re-button-icon {
      padding: 8px;
      height: 32px; }

.redactor-wrap .redactor-styles strong,
.redactor-wrap .redactor-styles b {
  line-height: 1.8rem !important; }

.redactor-wrap .redactor-layer {
  border: 1px solid #d8dde5;
  border-top: 0;
  padding: 24px 16px; }
  .redactor-wrap .redactor-layer p {
    color: #41506e;
    font-size: 1.4rem;
    line-height: 1.8rem !important; }

.redactor-wrap .redactor-layer * {
  font-family: 'Roboto', sans-serif !important;
  font-size: 1.4rem; }

.redactor-wrap .redactor-label {
  font-size: 1.2rem;
  color: #41506e;
  pointer-events: none;
  padding: 16px;
  line-height: 1.6rem;
  border: 1px solid #d8dde5; }

.redactor-wrap.disabled .redactor-label, .redactor-wrap.preview-info .redactor-label {
  padding-left: 0;
  padding-right: 0;
  border: none; }

.redactor-wrap.disabled .redactor-layer, .redactor-wrap.preview-info .redactor-layer {
  padding: 0;
  border: none;
  min-height: auto !important; }

.redactor-wrap.disabled .redactor-toolbar, .redactor-wrap.preview-info .redactor-toolbar {
  display: none; }

.datepicker-wrapper label {
  color: #41506e;
  font-size: 1.2rem;
  margin: 0px 16px 0px 0px; }

.datepicker-wrapper .date {
  position: relative; }

.datepicker-wrapper .btn, .datepicker-wrapper #main .yacht-nav-link span, #main .yacht-nav-link .datepicker-wrapper span {
  font-size: 1.4rem; }
  .datepicker-wrapper .btn.btn-date, .datepicker-wrapper #main .yacht-nav-link span.btn-date, #main .yacht-nav-link .datepicker-wrapper span.btn-date {
    height: 40px;
    background: #f9f9f9;
    color: #41506e;
    width: 128px;
    border: 1px solid #f9f9f9;
    transition: all 0.2s ease; }
    .datepicker-wrapper .btn.btn-date.changed, .datepicker-wrapper #main .yacht-nav-link span.btn-date.changed, #main .yacht-nav-link .datepicker-wrapper span.btn-date.changed {
      color: #41506e; }
    @media (max-width: 1199.98px) {
      .datepicker-wrapper .btn.btn-date, .datepicker-wrapper #main .yacht-nav-link span.btn-date, #main .yacht-nav-link .datepicker-wrapper span.btn-date {
        width: 112px; } }

.datepicker-wrapper .date-inner {
  position: relative;
  display: inline-block; }

.datepicker-wrapper .date-dropdown {
  position: absolute;
  opacity: 0;
  margin-top: 8px;
  padding: 8px;
  background: #f9f9f9;
  border: 1px solid #d8dde5;
  transition: all 0.2s ease;
  z-index: 2;
  visibility: hidden;
  right: 0;
  z-index: 100; }
  .datepicker-wrapper .date-dropdown.open {
    opacity: 1;
    visibility: visible; }
  .datepicker-wrapper .date-dropdown .side-links-wrap .btn, .datepicker-wrapper .date-dropdown .side-links-wrap #main .yacht-nav-link span, #main .yacht-nav-link .datepicker-wrapper .date-dropdown .side-links-wrap span {
    text-align: left;
    width: 100%;
    color: #41506e; }
    .datepicker-wrapper .date-dropdown .side-links-wrap .btn:hover, .datepicker-wrapper .date-dropdown .side-links-wrap #main .yacht-nav-link span:hover, #main .yacht-nav-link .datepicker-wrapper .date-dropdown .side-links-wrap span:hover {
      color: #3293f4; }
  .datepicker-wrapper .date-dropdown .rdt table {
    font-weight: 400;
    font-size: 1.4rem; }
  .datepicker-wrapper .date-dropdown .rdt .form-control {
    height: 0;
    padding: 0;
    border: none; }
  .datepicker-wrapper .date-dropdown .rdt .rdtPicker {
    width: 272px;
    height: 246px;
    position: relative;
    border: 1px solid #d8dde5;
    box-shadow: none;
    padding: 0;
    margin-top: 0; }
    .datepicker-wrapper .date-dropdown .rdt .rdtPicker .rdtMonths,
    .datepicker-wrapper .date-dropdown .rdt .rdtPicker .rdtYears {
      height: 100%; }
      .datepicker-wrapper .date-dropdown .rdt .rdtPicker .rdtMonths > :nth-child(2),
      .datepicker-wrapper .date-dropdown .rdt .rdtPicker .rdtYears > :nth-child(2) {
        height: calc(100% - 30px); }
    .datepicker-wrapper .date-dropdown .rdt .rdtPicker thead th {
      font-size: 1.2rem;
      border-bottom: none;
      height: 32px;
      color: #41506e; }
      .datepicker-wrapper .date-dropdown .rdt .rdtPicker thead th.rdtNext, .datepicker-wrapper .date-dropdown .rdt .rdtPicker thead th.rdtPrev {
        vertical-align: middle; }
        .datepicker-wrapper .date-dropdown .rdt .rdtPicker thead th.rdtNext span, .datepicker-wrapper .date-dropdown .rdt .rdtPicker thead th.rdtPrev span {
          font-size: 2.4rem;
          line-height: 0px; }
      .datepicker-wrapper .date-dropdown .rdt .rdtPicker thead th.rdtSwitch {
        font-size: 16px; }
    .datepicker-wrapper .date-dropdown .rdt .rdtPicker .dow {
      font-weight: inherit;
      border-bottom: 1px solid #d8dde5; }
    .datepicker-wrapper .date-dropdown .rdt .rdtPicker td {
      color: #41506e; }
      .datepicker-wrapper .date-dropdown .rdt .rdtPicker td.rdtToday:before {
        border-bottom-color: #3293f4; }
      .datepicker-wrapper .date-dropdown .rdt .rdtPicker td.rdtActive, .datepicker-wrapper .date-dropdown .rdt .rdtPicker td.rdtActive:hover {
        background-color: #aab4c7;
        text-shadow: none; }
      .datepicker-wrapper .date-dropdown .rdt .rdtPicker td.rdtActive {
        background: none;
        color: #3293f4; }
      .datepicker-wrapper .date-dropdown .rdt .rdtPicker td.rdtDisabled {
        color: #d8dde5; }
    .datepicker-wrapper .date-dropdown .rdt .rdtPicker td,
    .datepicker-wrapper .date-dropdown .rdt .rdtPicker th {
      height: 30px; }

.datepicker-wrapper.disabled .date {
  flex-direction: column; }
  .datepicker-wrapper.disabled .date label {
    margin: 0 0 12px 0; }
  .datepicker-wrapper.disabled .date .date-inner {
    color: #41506e;
    font-size: 1.4rem; }

.customDatePicker body span {
  font-size: 1rem !important; }

.customDatePicker .rdtPicker td.rdtActive,
.customDatePicker .rdtPicker td.rdtActive:hover {
  background: #41506e; }

.customDatePicker .rdtPicker thead tr:first-child th {
  font-weight: 400;
  color: #41506e; }

.customDatePicker .rdtPicker th {
  border: none; }

.customDatePicker .dow {
  color: #41506e;
  font-weight: 400;
  font-size: 1.2rem; }

.customDatePicker .rdtDay {
  color: #999999; }

.customDatePicker .rdtDay.rdtDisabled {
  color: #e5e8ed; }

.customDatePicker .rdtSwitch,
.customDatePicker .rdtDay,
.customDatePicker .rdtOld {
  font-size: 1.2rem; }

.customDatePicker .rdtNext,
.customDatePicker .rdtPrev {
  padding: 6px 0; }
  .customDatePicker .rdtNext span,
  .customDatePicker .rdtPrev span {
    font-size: 2rem; }

.customDatePicker .rdtPicker {
  position: absolute;
  top: 50px;
  background: #fff;
  border: 2px solid #f7f7f7;
  border-radius: 3px; }
  .customDatePicker .rdtPicker:before, .customDatePicker .rdtPicker:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .customDatePicker .rdtPicker:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #f7f7f7;
    border-width: 12px;
    margin-left: -114px; }
  .customDatePicker .rdtPicker:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -110px; }

.mt-select {
  display: flex;
  align-items: center; }
  .mt-select .css-1pcexqc-container,
  .mt-select .css-3esr4u-a11yText {
    width: 100%; }
    .mt-select .css-1pcexqc-container:focus, .mt-select .css-1pcexqc-container:active, .mt-select .css-1pcexqc-container:hover,
    .mt-select .css-3esr4u-a11yText:focus,
    .mt-select .css-3esr4u-a11yText:active,
    .mt-select .css-3esr4u-a11yText:hover {
      outline: 0;
      box-shadow: none; }
  .mt-select .css-xksf5l-control,
  .mt-select .css-2lntw8-control {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #d8dde5;
    border-radius: 0;
    background: transparent !important; }
    .mt-select .css-xksf5l-control:hover,
    .mt-select .css-2lntw8-control:hover {
      cursor: pointer;
      border-bottom: 1px solid #41506e; }
  .mt-select .css-1hwfws3 {
    padding-left: 0; }
    .mt-select .css-1hwfws3:focus, .mt-select .css-1hwfws3:active, .mt-select .css-1hwfws3:hover {
      outline: 0;
      box-shadow: none; }
  .mt-select .css-bgvzuu-indicatorSeparator {
    display: none; }
  .mt-select .css-16pqwjk-indicatorContainer {
    padding: 8px 3px; }
  .mt-select .css-es53b3-multiValue {
    background-color: transparent; }
  .mt-select .css-12jo7m5 {
    font-size: 1.4rem;
    color: #41506e;
    padding: 0;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: 300;
    padding-top: 3px; }
  .mt-select .css-kj6f9i-menu {
    margin: 0;
    padding: 0;
    z-index: 9999;
    cursor: pointer !important; }
    .mt-select .css-kj6f9i-menu > * {
      cursor: pointer !important; }
  .mt-select .css-19bqh2r {
    width: 20px;
    height: 20px;
    color: #41506e; }
  .mt-select .css-1alnv5e:hover,
  .mt-select .css-19bqh2r:hover {
    color: #41506e;
    background: transparent; }
  .mt-select .css-151xaom-placeholder {
    color: #41506e;
    font-size: 1.2rem;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase; }

.checkbox-tree-label {
  text-transform: uppercase;
  color: #41506e;
  font-weight: 400;
  font-size: 1.2rem; }

.react-checkbox-tree label {
  display: flex;
  align-items: center;
  padding: 3px; }
  .react-checkbox-tree label:hover {
    background-color: none; }

.react-checkbox-tree .rct-title {
  font-size: 1.45rem;
  color: #41506e;
  line-height: 1.8rem;
  text-transform: uppercase; }

.react-checkbox-tree .rct-checkbox {
  color: #41506e; }

.react-checkbox-tree .rct-icon {
  position: relative;
  color: #41506e; }

.react-checkbox-tree .rct-icon-uncheck::before,
.react-checkbox-tree .rct-icon-check::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  cursor: pointer;
  border: 1px solid #d8dde5; }

.react-checkbox-tree .rct-icon-check::before {
  background: #3293f4;
  border-color: #3293f4; }

.react-checkbox-tree .rct-icon-check::after {
  transform-origin: left top;
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  content: '';
  display: block;
  height: 12px;
  left: 2px;
  position: absolute;
  top: 9px;
  width: 5px;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  animation: check 0.8s;
  border-color: #fff; }

.in-cal {
  overflow-y: auto; }
  .in-cal__calendar {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase; }
  .in-cal__row {
    display: flex;
    padding: 7px 0 0;
    align-items: center; }
  .in-cal__month {
    color: #41506e;
    font-size: 1.15rem;
    font-weight: 500;
    padding: 7px 0;
    text-transform: uppercase;
    flex: 0 0 75px; }
  .in-cal__days {
    display: flex;
    justify-content: space-around; }
  .in-cal__day {
    font-size: 1.2rem;
    color: #41506e;
    padding: 5px 4px; }
    .in-cal__day.first {
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%; }
    .in-cal__day.last {
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%; }
    .in-cal__day--availiable {
      background-color: #bae8b8; }
    .in-cal__day--booked {
      background-color: #e8a2b2; }
    .in-cal__day--shipyard {
      background-color: #d6d6d6; }
    .in-cal__day--unavailable {
      background-color: #d6d6d6; }
    .in-cal__day--boat_show {
      background-color: #e8a2b2; }
    .in-cal__day--transit {
      background-color: #d6d6d6; }
    .in-cal__day--option {
      background-color: #f5e7ab; }

.booking__status__point {
  display: inline-block;
  padding: 5px;
  border-radius: 100%;
  margin-right: 5px; }
  .booking__status__point--availiable {
    background-color: #bae8b8; }
  .booking__status__point--booked {
    background-color: #e8a2b2; }
  .booking__status__point--shipyard {
    background-color: #d6d6d6; }
  .booking__status__point--unavailable {
    background-color: #d6d6d6; }
  .booking__status__point--boat_show {
    background-color: #e8a2b2; }
  .booking__status__point--transit {
    background-color: #d6d6d6; }
  .booking__status__point--option {
    background-color: #f5e7ab; }

.toggled-sidebar.fixed {
  position: fixed;
  top: 71px;
  height: 100vh;
  background: #fff;
  z-index: 1060;
  overflow: auto;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.175);
  width: 400px;
  right: -400px;
  padding: 0 15px;
  transition: 0.4s ease-in-out; }
  .toggled-sidebar.fixed.active {
    transform: translate3d(-400px, 0px, 0px); }

.instructions {
  padding: 15px 15px 0px;
  border: 1px solid #c5d5e6;
  margin-top: 25px;
  color: #41506e; }
  .instructions--info {
    background: #f7faff; }
  .instructions--error {
    background: #ffefef;
    border: 1px solid #ea9898; }
  .instructions__title, .instructions__footer {
    line-height: 1.4; }
  .instructions__messages-wrapper {
    line-height: 1.4;
    list-style-type: decimal;
    padding-left: 20px; }
  .instructions__messages {
    padding: 4px 0px; }
  .instructions__divider {
    margin: 1.5rem 0 2rem; }

.clearWebsiteCache {
  float: left;
  padding-right: 12px; }
  .clearWebsiteCache button {
    max-width: 90px;
    min-width: 100px;
    height: 32px;
    overflow: hidden; }
    .clearWebsiteCache button span {
      font-size: 1.2rem;
      font-weight: 400; }

.selectPhotosFromGallery {
  background-color: #eaeaea;
  padding: 20px;
  border-radius: 5px; }
  .selectPhotosFromGallery__label {
    margin-bottom: 10px; }
  .selectPhotosFromGallery__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 185px;
    overflow-y: auto; }
  .selectPhotosFromGallery__item {
    position: relative;
    height: 80px;
    width: 128px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 2px solid transparent;
    margin-right: 10px;
    margin-top: 10px; }
    .selectPhotosFromGallery__item--selected {
      border-color: #3293f4; }
  .selectPhotosFromGallery__download {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #3293f4;
    display: flex;
    justify-content: center;
    align-items: center; }
  .selectPhotosFromGallery__empty {
    font-size: 14px;
    font-style: italic;
    overflow-y: hidden; }

.contactProposalsMenuLinks {
  min-width: auto;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; }

.text--ellipsis {
  min-width: auto;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; }

.text--errormesage {
  position: absolute;
  top: 40px;
  left: -5px;
  padding-left: 20px;
  font-size: small;
  color: red; }

.rates {
  background: #eaeaea;
  padding: 40px 20px 0;
  border-radius: 5px; }
  .rates .redactor-label {
    display: none; }
  .rates .redactor-wrap .redactor-layer {
    min-height: 75px !important; }
  .rates .floating-label-wrapper {
    margin: 0 !important; }
  .rates__toolbar {
    padding: 20px 10px 0;
    margin-left: -20px;
    margin-right: -20px;
    background: white;
    display: flex;
    justify-content: flex-end; }
  .rates__row {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #fff; }
  .rates__seasons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    padding-left: 25px; }
    .rates__seasons h4 {
      padding-right: 10px;
      margin: 0; }
    .rates__seasons .btn, .rates__seasons #main .yacht-nav-link span, #main .yacht-nav-link .rates__seasons span {
      height: auto;
      padding: 2px 8px; }
  .rates__dates {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px; }
  .rates__spaces {
    width: 150px;
    margin-right: 30px; }
  .rates__notes {
    background: white;
    margin-left: 25px; }
  .rates__buttons {
    width: 100%;
    display: flex;
    margin-top: 10px;
    padding-left: 25px; }
    .rates__buttons .btn, .rates__buttons #main .yacht-nav-link span, #main .yacht-nav-link .rates__buttons span {
      height: auto;
      padding: 2px 8px; }
  .rates__index {
    padding-right: 10px; }
  .rates__currency {
    min-width: 70px; }

.external-yachts-filters {
  margin-top: 45px;
  margin-left: 30px; }

.external-yachts-filters-dropdown {
  border: 0px; }

.dv_status_Imported {
  color: #2dbb63 !important; }

.dv_status_not_Imported {
  color: red !important; }

.dv_status_needs_update {
  color: #0b80e0 !important; }

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto; }
  .modal-body__uploadField {
    color: red;
    position: relative;
    left: 280px;
    top: -106px;
    max-width: 30px;
    font-size: 11px;
    font-weight: 400; }

.phone-with-area-code__label {
  left: -10px !important;
  top: -15px !important; }

.phone-with-area-code .selected-flag {
  border: none !important;
  height: 28px !important;
  left: -8px !important; }

.phone-with-area-code.react-tel-input input[type='text'],
.phone-with-area-code.react-tel-input input[type='tel'] {
  border-bottom: 1px solid #d8dde5 !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-top: 0px !important;
  line-height: 25px !important;
  height: 30px !important;
  width: auto !important; }

.external-yacht-import-loader > .loader {
  position: relative !important;
  margin-left: 0% !important;
  margin-top: 0% !important; }

.custom-modal-warning__modal-title {
  color: #f0ad4e;
  font-weight: 500; }

.custom-modal-warning__section-title {
  font-weight: 400;
  color: black; }

html {
  width: 100vw;
  height: 100%;
  font-size: 63%;
  scroll-behavior: smooth; }

body {
  overflow-x: hidden;
  color: #aab4c7; }

.loader {
  margin-left: 50%; }

.sidebar-list .mail-svg {
  fill: #41506e; }

.smallDesc {
  overflow-y: auto;
  max-height: 50px;
  padding: 5px 5px 5px 0; }

.smallDesc > * {
  margin: 0;
  padding: 0;
  margin-right: 20px; }

/* Login Start */
.login-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  justify-content: center;
  background: #f9f9f9; }
  .login-wrapper .auth-wrap {
    position: absolute;
    bottom: 4%;
    color: #aab4c7; }
    .login-wrapper .auth-wrap > span {
      font-size: 1.4rem; }
    .login-wrapper .auth-wrap svg {
      fill: #41506e; }
  .login-wrapper .form-wrap {
    width: 40%;
    background: #fff; }
    @media (max-width: 1199.98px) {
      .login-wrapper .form-wrap {
        width: 80%; } }
    .login-wrapper .form-wrap.reset-password-wrap {
      width: 25%; }
      .login-wrapper .form-wrap.reset-password-wrap .login-form {
        padding: 88px; }
      @media (max-width: 1199.98px) {
        .login-wrapper .form-wrap.reset-password-wrap {
          width: 50%; } }
    .login-wrapper .form-wrap h4 {
      margin-bottom: 16px;
      font-size: 2rem; }
    .login-wrapper .form-wrap strong {
      font-size: 1.2rem; }
    .login-wrapper .form-wrap .login-brand-wrap {
      height: 100%;
      background: #41506e;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px; }
      .login-wrapper .form-wrap .login-brand-wrap h4 {
        margin-bottom: 0;
        color: white; }
      .login-wrapper .form-wrap .login-brand-wrap .logo-fill {
        fill: #fff; }
      .login-wrapper .form-wrap .login-brand-wrap .mono-wrap strong > a {
        color: #aab4c7; }
        .login-wrapper .form-wrap .login-brand-wrap .mono-wrap strong > a:visited {
          color: #aab4c7; }
    .login-wrapper .form-wrap .login-form {
      padding: 72px 72px 72px 40px; }
      .login-wrapper .form-wrap .login-form h4 {
        color: #41506e; }
      .login-wrapper .form-wrap .login-form strong {
        color: #d8dde5; }
      .login-wrapper .form-wrap .login-form button[type='submit'] + strong {
        color: #aab4c7; }
        .login-wrapper .form-wrap .login-form button[type='submit'] + strong > a {
          color: #41506e; }
      .login-wrapper .form-wrap .login-form a > strong {
        color: #aab4c7; }
    .login-wrapper .form-wrap .btn-primary,
    .login-wrapper .form-wrap .btn-success {
      font-size: 1.2rem;
      text-transform: uppercase; }
  .login-wrapper.home-wrapper {
    flex-direction: column; }
    .login-wrapper.home-wrapper .brand-wrap svg {
      fill: #41506e; }
    .login-wrapper.home-wrapper .brand-wrap + div {
      font-size: 1.2rem; }
    .login-wrapper.home-wrapper .home-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin: 2em 0;
      width: 100%;
      position: relative;
      text-align: center;
      transition: all 0.4s ease;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0), 0 4px 16px rgba(0, 0, 0, 0);
      max-width: 512px;
      height: 22rem; }
      .login-wrapper.home-wrapper .home-link > span {
        display: block;
        transition: all 0.4s ease; }
        .login-wrapper.home-wrapper .home-link > span.link-head {
          font-size: 2rem;
          color: #41506e;
          margin-bottom: 24px; }
        .login-wrapper.home-wrapper .home-link > span.link-text {
          font-size: 1.2rem;
          color: #d8dde5; }
      .login-wrapper.home-wrapper .home-link svg {
        transition: all 0.4s ease;
        fill: #41506e;
        margin-bottom: 24px; }
      .login-wrapper.home-wrapper .home-link:hover {
        background: #41506e;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.2); }
        .login-wrapper.home-wrapper .home-link:hover > span {
          color: #d8dde5; }
        .login-wrapper.home-wrapper .home-link:hover svg {
          fill: #d8dde5; }
    .login-wrapper.home-wrapper .home-divader {
      font-size: 1.2rem;
      position: relative; }
      .login-wrapper.home-wrapper .home-divader:before, .login-wrapper.home-wrapper .home-divader:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 1px;
        background: #d8dde5;
        top: 50%; }
      .login-wrapper.home-wrapper .home-divader:before {
        left: -24px; }
      .login-wrapper.home-wrapper .home-divader:after {
        right: -24px; }

/* Login End */
/* Header Start */
.header {
  position: fixed;
  width: 85vw;
  left: 15vw;
  top: 0;
  padding: 0px 24px;
  height: 72px;
  border-bottom: 1px solid #d8dde5;
  -webkit-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  background: #fff;
  z-index: 10; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .header {
      left: 20vw; } }
  .header .search-filter .floating-label-wrapper {
    margin: 0;
    width: 100%; }
    .header .search-filter .floating-label-wrapper .form-control {
      padding-right: 24px; }
      .header .search-filter .floating-label-wrapper .form-control::-webkit-input-placeholder {
        color: #aab4c7;
        font-size: 12px;
        opacity: 1;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .header .search-filter .floating-label-wrapper .form-control:-moz-placeholder {
        color: #aab4c7;
        font-size: 12px;
        opacity: 1;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .header .search-filter .floating-label-wrapper .form-control::-moz-placeholder {
        color: #aab4c7;
        font-size: 12px;
        opacity: 1;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
      .header .search-filter .floating-label-wrapper .form-control:-ms-input-placeholder {
        color: #aab4c7;
        font-size: 12px;
        opacity: 1;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        font-style: normal; }
    .header .search-filter .floating-label-wrapper:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 8px;
      right: 4px;
      background: url("../svgs/search.svg") no-repeat #fff; }
  .header > .row {
    height: 100%; }
    .header > .row .active-nav {
      align-items: center;
      margin: 0; }
      .header > .row .active-nav > li {
        height: 72px;
        position: relative; }
        .header > .row .active-nav > li.plain-links {
          margin: 0 8px; }
          @media screen and (min-width: 1050px) {
            .header > .row .active-nav > li.plain-links {
              margin: 0 12px; } }
          @media screen and (min-width: 1300px) {
            .header > .row .active-nav > li.plain-links {
              margin: 0 16px; } }
        .header > .row .active-nav > li.nav-icon-wrap {
          margin: 0px 16px 0px 0px; }
          .header > .row .active-nav > li.nav-icon-wrap div {
            height: 32px; }
        .header > .row .active-nav > li > div svg {
          fill: #41506e; }
        .header > .row .active-nav > li > a {
          color: #aab4c7;
          font-size: 1.2rem;
          text-transform: uppercase;
          height: 100%; }
          .header > .row .active-nav > li > a svg {
            fill: #3293f4; }
          .header > .row .active-nav > li > a:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0px;
            left: 0;
            background: #41506e;
            opacity: 0; }
          .header > .row .active-nav > li > a.active, .header > .row .active-nav > li > a:hover {
            color: #41506e; }
            .header > .row .active-nav > li > a.active:before, .header > .row .active-nav > li > a:hover:before {
              opacity: 1; }
  .header .role-switch-wrapper .toggle-wrapper {
    margin: 0; }
    .header .role-switch-wrapper .toggle-wrapper .toggle {
      height: 32px; }
      .header .role-switch-wrapper .toggle-wrapper .toggle .toggle-label {
        margin: 0px 8px;
        color: #aab4c7; }
        .header .role-switch-wrapper .toggle-wrapper .toggle .toggle-label.active {
          color: #41506e; }
      .header .role-switch-wrapper .toggle-wrapper .toggle label input[type='checkbox']:checked + span, .header .role-switch-wrapper .toggle-wrapper .toggle label input[type='checkbox']:checked + span:before {
        background-color: #41506e; }
      .header .role-switch-wrapper .toggle-wrapper .toggle label input[type='checkbox']:checked + span:after {
        border-color: #41506e; }
  .header + .content-inner {
    top: 80px;
    z-index: 1;
    position: relative;
    padding: 0px 16px 0px 56px;
    margin-bottom: 96px; }
    .header + .content-inner > .section:first-of-type {
      margin-top: 112px; }
    .header + .content-inner .search-filter .floating-label-wrapper {
      margin: 0;
      width: 100%; }
      .header + .content-inner .search-filter .floating-label-wrapper.no-float .float.top {
        display: none; }
      .header + .content-inner .search-filter .floating-label-wrapper .form-control {
        padding-right: 24px; }
        .header + .content-inner .search-filter .floating-label-wrapper .form-control::-webkit-input-placeholder {
          color: #aab4c7;
          font-size: 12px;
          opacity: 1;
          font-weight: 400;
          font-family: 'Roboto', sans-serif;
          font-style: normal; }
        .header + .content-inner .search-filter .floating-label-wrapper .form-control:-moz-placeholder {
          color: #aab4c7;
          font-size: 12px;
          opacity: 1;
          font-weight: 400;
          font-family: 'Roboto', sans-serif;
          font-style: normal; }
        .header + .content-inner .search-filter .floating-label-wrapper .form-control::-moz-placeholder {
          color: #aab4c7;
          font-size: 12px;
          opacity: 1;
          font-weight: 400;
          font-family: 'Roboto', sans-serif;
          font-style: normal; }
        .header + .content-inner .search-filter .floating-label-wrapper .form-control:-ms-input-placeholder {
          color: #aab4c7;
          font-size: 12px;
          opacity: 1;
          font-weight: 400;
          font-family: 'Roboto', sans-serif;
          font-style: normal; }
      .header + .content-inner .search-filter .floating-label-wrapper:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: 8px;
        right: 4px;
        background: url("../svgs/search.svg") no-repeat #fff; }
  @media (max-width: 1199.98px) {
    .header + .content-inner {
      padding-left: 0px;
      padding-right: 0px; } }

.toggle-wrapper {
  padding-left: 16px; }

.tabs-wrapper {
  display: flex; }

/* Header End */
/* Sidebar Start */
#sidebar {
  height: 100vh;
  position: fixed;
  z-index: 10;
  width: 15vw;
  background: #f7f7f7;
  top: 0;
  -webkit-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    #sidebar {
      width: 20vw; } }
  #sidebar.sidebar-admin {
    background: #41506e; }
    #sidebar.sidebar-admin .sidebar-head {
      height: 300px; }
      #sidebar.sidebar-admin .sidebar-head .sidebar-brand svg {
        fill: #fff; }
      #sidebar.sidebar-admin .sidebar-head .sidebar-user .user-name span {
        color: #fff; }
    #sidebar.sidebar-admin .sidebar-body .sidebar-list > li > a > span {
      color: #d8dde5; }
    #sidebar.sidebar-admin .sidebar-body .sidebar-list > li > a .mail-svg,
    #sidebar.sidebar-admin .sidebar-body .sidebar-list > li > a svg {
      fill: #d8dde5; }
      #sidebar.sidebar-admin .sidebar-body .sidebar-list > li > a .mail-svg path,
      #sidebar.sidebar-admin .sidebar-body .sidebar-list > li > a svg path {
        fill: #d8dde5 !important; }
    #sidebar.sidebar-admin .sidebar-body .sidebar-list > li > a:hover, #sidebar.sidebar-admin .sidebar-body .sidebar-list > li > a.active {
      background: rgba(255, 255, 255, 0.2); }
    #sidebar.sidebar-admin .sidebar-body .sidebar-actions > li .sidebar-toggle:before, #sidebar.sidebar-admin .sidebar-body .sidebar-actions > li .sidebar-toggle:after {
      background: #d8dde5; }
  #sidebar .sidebar-head {
    padding: 24px;
    border-bottom: 1px solid rgba(216, 221, 229, 0.2); }
    @media (max-width: 1199.98px) {
      #sidebar .sidebar-head {
        padding: 16px; } }
    #sidebar .sidebar-head .sidebar-brand {
      position: relative;
      left: 5px; }
      #sidebar .sidebar-head .sidebar-brand svg {
        fill: #41506e; }
    #sidebar .sidebar-head .sidebar-user {
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      height: 180px;
      overflow: hidden;
      position: relative; }
      #sidebar .sidebar-head .sidebar-user.sidebar-closed {
        margin-top: 16px; }
      #sidebar .sidebar-head .sidebar-user .user-avatar {
        margin: 0 auto;
        position: relative; }
        #sidebar .sidebar-head .sidebar-user .user-avatar img {
          width: 88px;
          height: 88px;
          border: 1px solid #41506e;
          border-radius: 100%;
          object-fit: cover;
          transition: width 0.3s, height 0.3s; }
        #sidebar .sidebar-head .sidebar-user .user-avatar .edit-icon {
          width: 25px;
          height: 25px;
          position: absolute;
          display: block;
          right: 0;
          bottom: 0;
          background: white;
          border-radius: 50%;
          border: 1px solid #41506e; }
          #sidebar .sidebar-head .sidebar-user .user-avatar .edit-icon svg {
            padding: 4px;
            width: 24px;
            display: block;
            fill: #41506e; }
        #sidebar .sidebar-head .sidebar-user .user-avatar:hover .edit-icon {
          background: #41506e;
          border-color: white; }
          #sidebar .sidebar-head .sidebar-user .user-avatar:hover .edit-icon svg {
            fill: white; }
      #sidebar .sidebar-head .sidebar-user .user-name span {
        color: #41506e; }
      #sidebar .sidebar-head .sidebar-user .user-name .first-letter {
        display: none; }
      #sidebar .sidebar-head .sidebar-user .user-info {
        padding-top: 16px; }
      @media (min-width: 992px) and (max-width: 1199.98px) {
        #sidebar .sidebar-head .sidebar-user .user-info {
          padding-top: 0.8rem;
          font-size: 1.2rem; } }
  #sidebar .sidebar-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 300px);
    overflow: auto; }
    @media (max-height: 768px) {
      #sidebar .sidebar-body {
        overflow-y: auto; } }
    #sidebar .sidebar-body .sidebar-list > li > a {
      display: flex;
      padding: 16px 24px;
      align-items: center; }
      #sidebar .sidebar-body .sidebar-list > li > a span {
        font-size: 1.4rem;
        color: #15284b; }
        @media (max-width: 1199.98px) {
          #sidebar .sidebar-body .sidebar-list > li > a span {
            line-height: 1.6; } }
      #sidebar .sidebar-body .sidebar-list > li > a > div {
        height: 24px;
        padding-right: 24px;
        position: relative;
        left: 5px; }
      #sidebar .sidebar-body .sidebar-list > li > a svg {
        fill: #41506e; }
        #sidebar .sidebar-body .sidebar-list > li > a svg path {
          fill: #41506e; }
      #sidebar .sidebar-body .sidebar-list > li > a:hover, #sidebar .sidebar-body .sidebar-list > li > a.active {
        background: #d8dde5; }
    #sidebar .sidebar-body .sidebar-actions {
      width: 100%; }
      #sidebar .sidebar-body .sidebar-actions > li:first-child {
        border-bottom: 1px solid rgba(216, 221, 229, 0.2); }
        @media (max-width: 991.98px) {
          #sidebar .sidebar-body .sidebar-actions > li:first-child {
            border-bottom: none; } }
      #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle {
        background: none;
        outline: none;
        margin: auto;
        border: none;
        position: relative;
        width: 16px;
        height: 24px;
        margin: 8px 0px 8px 24px;
        box-sizing: content-box;
        cursor: pointer;
        padding: 0px 8px; }
        #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle::before, #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle::after {
          content: '';
          width: 16px;
          height: 2px;
          background: #41506e;
          display: block;
          position: absolute;
          border-radius: 1px;
          transition: transform 0.2s ease, top 0.2s ease 0.1s; }
        #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle::before {
          top: 8px; }
        #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle::after {
          top: 16px; }
        #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle.active::before, #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle.active::after {
          transition: top 0.2s ease, transform 0.2s ease 0.1s;
          top: 50%; }
        #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle.active::before {
          transform: rotate(45deg); }
        #sidebar .sidebar-body .sidebar-actions > li .sidebar-toggle.active::after {
          transform: rotate(-45deg); }

@media (max-width: 991.98px) {
  .menu-close #sidebar {
    width: 25vw;
    z-index: 1060;
    box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.175); } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .menu-close #sidebar {
    box-shadow: 2px 0px 10px 2px rgba(0, 0, 0, 0.175); } }

.menu-open #sidebar .sidebar-head .sidebar-user .user-name .fullname,
.menu-closing #sidebar .sidebar-head .sidebar-user .user-name .fullname {
  display: none; }

.menu-open #sidebar .sidebar-head .sidebar-user .user-name .first-letter,
.menu-closing #sidebar .sidebar-head .sidebar-user .user-name .first-letter {
  display: inline-block;
  width: 40px;
  word-wrap: break-word; }

.menu-open #sidebar .sidebar-head .sidebar-user .user-info,
.menu-closing #sidebar .sidebar-head .sidebar-user .user-info {
  display: none; }

.menu-open #sidebar .sidebar-body .sidebar-list > li > a span,
.menu-closing #sidebar .sidebar-body .sidebar-list > li > a span {
  display: none; }

.menu-open #sidebar {
  width: 88px; }
  .menu-open #sidebar .sidebar-user .user-avatar img {
    width: 40px;
    height: 40px; }
  .menu-open #sidebar .sidebar-user .user-avatar .edit-icon {
    width: 20px;
    bottom: -4px;
    height: 20px; }
    .menu-open #sidebar .sidebar-user .user-avatar .edit-icon svg {
      width: 20px; }

.menu-open #header {
  width: calc(100% - 88px);
  left: 88px; }

.menu-open #main {
  margin-left: 88px; }

@media (max-width: 1199.98px) {
  #sidebar {
    z-index: 1060; }
  #header {
    left: 88px;
    width: calc(100% - 88px); } }

/* Sidebasr End */
/* Main Start */
#main {
  margin-left: 15vw;
  padding: 32px;
  -webkit-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* easeOutQuad */
  transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  /* Destinations Manage Start */
  /* Destinations Manage End */
  /* Destinations Manage Start */
  /* Destinations Manage End */ }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    #main {
      margin-left: 88px; } }
  @media (max-width: 991.98px) {
    #main {
      margin-left: 88px; } }
  #main .result-yacht-wrapper {
    margin-bottom: 24px; }
    #main .result-yacht-wrapper .small-head {
      font-size: 1.2rem;
      color: #41506e; }
    #main .result-yacht-wrapper .heading-label {
      margin-bottom: 8px;
      text-transform: uppercase; }
    #main .result-yacht-wrapper .heading-body {
      font-size: 1.4rem;
      color: #41506e;
      word-wrap: break-word; }
    #main .result-yacht-wrapper .yacht-header-wrapper {
      padding: 16px;
      background: #f7f7f7;
      border-bottom: 1px solid #d8dde5; }
      #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading {
        margin: 20px 0;
        color: #41506e; }
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading .yacht-name {
          color: #3293f4;
          font-size: 1.4rem;
          font-weight: 400; }
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading .yacht-name-link {
          margin-bottom: 0.5rem; }
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading .previous-name-wrapper,
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading .previous-name {
          font-size: 1.2rem;
          color: #41506e;
          font-weight: 500; }
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading .previous-name-wrapper {
          margin-top: 0.5rem; }
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading .previous-name {
          margin-left: 0.5rem; }
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading .checkbox-wrapper {
          margin: 0px 16px 0px 0px; }
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading.yacht-actions ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-end !important; }
        #main .result-yacht-wrapper .yacht-header-wrapper .yacht-heading.yacht-actions li {
          padding: 12px; }
      @media (max-width: 1199.98px) {
        #main .result-yacht-wrapper .yacht-header-wrapper .table-td-actions {
          justify-content: flex-end !important; } }
    #main .result-yacht-wrapper .yacht-body-wrapper {
      padding: 24px 16px; }
      #main .result-yacht-wrapper .yacht-body-wrapper .yacht-status-label {
        position: absolute;
        color: white;
        z-index: 1;
        background: #ee394e;
        width: 15rem;
        text-align: center;
        transform: rotate(-45deg);
        padding: 0.8rem 0;
        top: 0;
        left: 0;
        top: -24px;
        left: -64px;
        transform-origin: right top;
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 1px; }
      #main .result-yacht-wrapper .yacht-body-wrapper .yacht-image {
        background-color: #fff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
        width: 100%;
        padding-top: 100%;
        /* 1:1 Aspect Ratio */
        position: relative;
        /* If you want text inside of it */
        overflow: hidden; }
        #main .result-yacht-wrapper .yacht-body-wrapper .yacht-image.background-yacht-filling {
          border: 1px solid #e5e8ed;
          background-color: #fbfbfb;
          background-position: center; }
      #main .result-yacht-wrapper .yacht-body-wrapper .btn-favorite {
        padding: 0;
        height: 16px;
        margin-left: auto; }
  #main .content-inner .yachts-filters-wrapper.with-shadow {
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1); }
  #main .yachts-inner-wrapper {
    position: relative; }
    #main .yachts-inner-wrapper .table-actions {
      z-index: 11;
      position: relative; }
      #main .yachts-inner-wrapper .table-actions .btn-action svg {
        height: 16px !important; }
    #main .yachts-inner-wrapper .yachts-filters-wrapper {
      position: absolute;
      background: #fff;
      z-index: 3;
      position: absolute;
      width: calc(100% - 16px);
      background: #fff;
      z-index: 10;
      box-shadow: 0 3px 16px rgba(0, 0, 0, 0.175);
      transition: max-height 0.3s ease-in;
      overflow: hidden;
      max-height: 0;
      top: -8px;
      border-radius: 25px; }
      #main .yachts-inner-wrapper .yachts-filters-wrapper.toggled {
        transition: max-height 0.3s ease-in;
        max-height: 1940px;
        overflow: visible; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper.toggled .list-form {
          opacity: 1; }
      #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form {
        position: relative;
        opacity: 0; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .filter-label {
          font-size: 1.2rem;
          color: #41506e;
          margin-bottom: 16px; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .checkbox-wrapper {
          margin-bottom: 8px; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .floating-label-wrapper {
          margin-bottom: 40px; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .collapse-wrap:before {
          background-image: linear-gradient(to right, #5fcf80 50%, rgba(255, 255, 255, 0) 0%) !important; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .collapse-wrap .collapse-label {
          color: #5fcf80 !important; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .collapse-wrap .trigger-collapse > button {
          border-color: #5fcf80 !important; }
          #main .yachts-inner-wrapper .yachts-filters-wrapper .list-form .collapse-wrap .trigger-collapse > button > span {
            color: #5fcf80 !important; }
      #main .yachts-inner-wrapper .yachts-filters-wrapper .multiple-filters-wrap {
        position: relative; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper .multiple-filters-wrap .multiple-wrap > div:first-child {
          position: absolute;
          right: -164px;
          top: 16px; }
        #main .yachts-inner-wrapper .yachts-filters-wrapper .multiple-filters-wrap .list-unstyled {
          height: 160px;
          overflow-y: auto;
          overflow-x: hidden; }
          #main .yachts-inner-wrapper .yachts-filters-wrapper .multiple-filters-wrap .list-unstyled::-webkit-scrollbar {
            width: 6px; }
          #main .yachts-inner-wrapper .yachts-filters-wrapper .multiple-filters-wrap .list-unstyled::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
            -webkit-border-radius: 10px;
            border-radius: 10px; }
          #main .yachts-inner-wrapper .yachts-filters-wrapper .multiple-filters-wrap .list-unstyled::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: #dedede;
            -webkit-box-shadow: inset 0 0 3px #dedede; }
          #main .yachts-inner-wrapper .yachts-filters-wrapper .multiple-filters-wrap .list-unstyled .floating-label-wrapper {
            margin-bottom: 2.4rem; }
    #main .yachts-inner-wrapper .broker-search-message {
      position: absolute;
      top: 30vh;
      width: 100%;
      text-align: center; }
  #main .selected-yachts-wrapper .selected-yachts-inner {
    position: relative;
    width: 100%;
    border-left: 1px solid #d8dde5;
    padding: 0px 0px 0px 32px; }
    #main .selected-yachts-wrapper .selected-yachts-inner .total-wrap {
      color: #41506e;
      font-weight: 400;
      font-size: 1.2rem; }
    #main .selected-yachts-wrapper .selected-yachts-inner .selected-yachts-header {
      padding: 0px 24px;
      margin-bottom: 8px; }
      #main .selected-yachts-wrapper .selected-yachts-inner .selected-yachts-header .btn-clear {
        padding: 0; }
    #main .selected-yachts-wrapper .selected-yachts-inner .table-actions {
      margin-bottom: 16px; }
      #main .selected-yachts-wrapper .selected-yachts-inner .table-actions .action-label {
        padding: 0px 8px; }
      #main .selected-yachts-wrapper .selected-yachts-inner .table-actions .btn-offer {
        margin: 0;
        font-size: 1.4rem !important;
        padding: 0px 16px; }
    #main .selected-yachts-wrapper .selected-yachts-inner .yacht-row-wrapper {
      box-shadow: 0 3px 16px rgba(0, 0, 0, 0.175);
      max-height: 70vh;
      overflow-y: auto;
      overflow-x: hidden; }
    #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap {
      padding: 16px 24px; }
      #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap:first-child {
        padding-top: 24px; }
      #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap:last-child {
        padding-bottom: 24px; }
      #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap .yacht-image {
        background-color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        padding-top: 100%;
        /* 1:1 Aspect Ratio */ }
      #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap a {
        font-size: 1.4rem; }
      #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap .btn-td-action {
        background: none;
        padding: 0;
        height: 20px; }
        #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap .btn-td-action svg {
          fill: #41506e; }
      #main .selected-yachts-wrapper .selected-yachts-inner .yacht-wrap .more-yachts {
        color: #41506e;
        font-size: 1.4rem; }
    #main .selected-yachts-wrapper .selected-yachts-inner.togglable-sidebar {
      transition: 0.7s ease-out; }
      @media (max-width: 1199.98px) {
        #main .selected-yachts-wrapper .selected-yachts-inner.togglable-sidebar.togglable-rsidebar-hidden {
          right: -25%; } }
    #main .selected-yachts-wrapper .selected-yachts-inner .sidebar-close-wrapper {
      display: none;
      background-color: #f9f9f9; }
      #main .selected-yachts-wrapper .selected-yachts-inner .sidebar-close-wrapper .sidebar-close-button {
        color: #41506e;
        font-size: 1.2rem;
        font-weight: 500; }
  @media (max-width: 1199.98px) {
    #main .selected-yachts-wrapper .selected-yachts-inner {
      height: 100vh;
      background: white;
      z-index: 1060;
      overflow: auto; }
      #main .selected-yachts-wrapper .selected-yachts-inner .yacht-row-wrapper {
        max-height: 72vh; }
      #main .selected-yachts-wrapper .selected-yachts-inner .table-actions {
        background: transparent; }
        #main .selected-yachts-wrapper .selected-yachts-inner .table-actions .action-label {
          display: none; }
        #main .selected-yachts-wrapper .selected-yachts-inner .table-actions .btn-offer {
          margin: 0 auto; }
      #main .selected-yachts-wrapper .selected-yachts-inner .info-label {
        display: none; }
      #main .selected-yachts-wrapper .selected-yachts-inner .separator {
        margin-left: -1.6rem;
        width: calc(100% + 3.2rem);
        border: 1px solid #f7f7f7; } }
  #main .brokers-box {
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.175);
    min-height: 350px;
    padding: 32px; }
  #main .brokers-carousel .slick-slider {
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.175);
    height: 100%; }
    #main .brokers-carousel .slick-slider .slick-slide {
      padding: 24px; }
    #main .brokers-carousel .slick-slider .image-wrap {
      background-color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 72px; }
    #main .brokers-carousel .slick-slider .slick-dots {
      left: 0; }
      #main .brokers-carousel .slick-slider .slick-dots > li {
        margin: 0; }
  #main .yacht-notes p {
    line-height: 1.4 !important; }
  #main .yacht-image-sales-listing {
    height: auto;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: move; }
    #main .yacht-image-sales-listing img {
      width: 100%;
      height: auto;
      max-height: 360px;
      object-fit: cover;
      object-position: center; }
  #main .yacht-photo-wrap .yacht-image {
    background-color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 60%; }
  #main .yacht-photo-wrap .yacht-thumbs .thumb {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% top;
    position: relative;
    width: 160px;
    height: 104px;
    cursor: pointer; }
    #main .yacht-photo-wrap .yacht-thumbs .thumb:hover, #main .yacht-photo-wrap .yacht-thumbs .thumb.selected {
      opacity: 0.8; }
  #main .yacht-sales-listing {
    pointer-events: none; }
    #main .yacht-sales-listing .blue-bg {
      color: white;
      background-color: #003366; }
    #main .yacht-sales-listing .notes {
      padding: 2px;
      padding-right: 14px;
      text-align: end;
      color: #036;
      font-weight: 400; }
    #main .yacht-sales-listing .footer {
      min-height: 34px; }
      #main .yacht-sales-listing .footer .col-8 {
        font-size: 16px;
        line-height: 16px; }
      #main .yacht-sales-listing .footer .col-4 {
        font-size: 18px;
        line-height: 16px;
        padding-right: 14px; }
    #main .yacht-sales-listing .sales-listing-yachts-controls {
      pointer-events: all; }
    #main .yacht-sales-listing .brokers-field {
      min-height: 60%;
      text-align: right;
      color: #036;
      padding-right: 14px; }
      #main .yacht-sales-listing .brokers-field div.field {
        padding-bottom: 6px; }
      #main .yacht-sales-listing .brokers-field div.name {
        margin-top: 12px;
        font-weight: bold; }
    #main .yacht-sales-listing .yacht-name {
      text-align: end;
      padding-right: 14px;
      font-size: 32px;
      color: #036; }
  #main .yacht-feature-photo {
    width: 100%;
    padding-top: 100%;
    position: relative; }
    #main .yacht-feature-photo .upload-single-image {
      position: absolute;
      top: 0; }
      #main .yacht-feature-photo .upload-single-image img {
        object-fit: contain; }
    #main .yacht-feature-photo .input-wrap {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%; }
  #main .yacht-forms-wrapper .yacht-forms-inner > .collapse-wrapper {
    margin-bottom: 16px; }
    #main .yacht-forms-wrapper .yacht-forms-inner > .collapse-wrapper .collapse-label {
      font-size: 1.6rem;
      font-weight: 300;
      color: #aab4c7; }
    #main .yacht-forms-wrapper .yacht-forms-inner > .collapse-wrapper .collapse-wrap {
      margin-bottom: 8px; }
      #main .yacht-forms-wrapper .yacht-forms-inner > .collapse-wrapper .collapse-wrap:before {
        background-image: linear-gradient(to right, #aab4c7 50%, rgba(255, 255, 255, 0) 0%); }
      #main .yacht-forms-wrapper .yacht-forms-inner > .collapse-wrapper .collapse-wrap .trigger-collapse > button {
        border-color: #aab4c7; }
        #main .yacht-forms-wrapper .yacht-forms-inner > .collapse-wrapper .collapse-wrap .trigger-collapse > button span {
          color: #aab4c7; }
    #main .yacht-forms-wrapper .yacht-forms-inner > .collapse-wrapper .datepicker-wrapper {
      margin-top: 24px;
      margin-bottom: 32px; }
      #main .yacht-forms-wrapper .yacht-forms-inner > .collapse-wrapper .datepicker-wrapper.disabled {
        margin-top: 16px; }
  #main .yacht-forms-wrapper .yacht-forms-inner.view .floating-label-wrapper {
    margin-bottom: 16px; }
  #main .yacht-nav-link {
    height: 32px;
    text-transform: initial;
    padding: 0; }
    #main .yacht-nav-link.yacht-nav-link-edit span {
      width: 88px; }
    #main .yacht-nav-link.yacht-nav-link-delete .btn, #main .yacht-nav-link.yacht-nav-link-delete span {
      width: 104px; }
    #main .yacht-nav-link.red span {
      background: #ee394e; }
    #main .yacht-nav-link:before {
      display: none !important; }
    #main .yacht-nav-link span {
      background: #3293f4;
      color: #fff;
      line-height: 0;
      font-size: 1.2rem;
      padding: 4px 16px;
      height: 3.2rem; }
      #main .yacht-nav-link span:hover {
        background: #41506e; }
      #main .yacht-nav-link span > div {
        margin-right: 8px; }
        #main .yacht-nav-link span > div > div {
          line-height: 0; }
        #main .yacht-nav-link span > div svg {
          fill: #fff; }
  #main .yacht-video-list .yacht-upload-video.video-upload-button .upload-input {
    padding: 0.8rem;
    width: 3.2rem;
    background: transparent; }
  #main .yacht-video-list .btn-remove {
    bottom: 50%; }
  #main .component-dragged {
    background-color: white;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.175); }
  #main .selection-remove-icon {
    width: 24px;
    height: 24px; }
    #main .selection-remove-icon:hover {
      color: #ee394e; }
  #main .sidebar-button {
    display: none;
    background-color: #41506e; }
    @media (max-width: 1199.98px) {
      #main .sidebar-button {
        display: inherit; } }
  #main .status-edit .status-toggle {
    cursor: pointer; }
  #main .yacht-status-container {
    display: flex;
    flex-direction: column; }
    #main .yacht-status-container .yacht-status {
      color: #41506e;
      border-color: #41506e; }
    #main .yacht-status-container .yacht-status-detail {
      color: #aab4c7;
      border-color: #aab4c7; }
    #main .yacht-status-container .yacht-is_archived {
      color: #ee394e;
      border-color: #aab4c7;
      font-size: 1.2rem;
      font-weight: 500;
      border: 1px solid;
      padding: 0.6rem 0.8rem;
      display: inline-block;
      margin-right: auto; }
    #main .yacht-status-container .yacht-status,
    #main .yacht-status-container .yacht-status-detail {
      font-size: 1rem;
      font-weight: 500;
      border: 1px solid;
      padding: 0.6rem 0.8rem;
      margin: 0.2rem 0;
      display: inline-block;
      margin-right: auto; }
  #main .destinations-manage {
    padding-bottom: 24px; }
    #main .destinations-manage .suggestions-wrap .suggestions-list > div > button {
      color: #aab4c7;
      fill: #aab4c7; }
      #main .destinations-manage .suggestions-wrap .suggestions-list > div > button.selected {
        color: #41506e; }
        #main .destinations-manage .suggestions-wrap .suggestions-list > div > button.selected svg {
          fill: #41506e; }
      #main .destinations-manage .suggestions-wrap .suggestions-list > div > button:hover {
        color: #41506e; }
        #main .destinations-manage .suggestions-wrap .suggestions-list > div > button:hover svg {
          fill: #41506e; }
    #main .destinations-manage .video-upload-button .upload-input {
      padding: 8px;
      background: transparent; }
  #main .places-manage .coordinates-wrap .lan-long .day-trip {
    height: 32px; }

@media (max-width: 1199.98px) {
  /**
   *  Change padding from main to content inner
   *  in order for content-inner to be able to capture
   *  touch events from the edge of the screen.
   */
  #main {
    padding-left: 0;
    padding-right: 0; }
  .header + .content-inner {
    padding-left: 3.2rem;
    padding-right: 3.2rem; } }

.status-dropdown .custom-select-wrapper {
  flex: 1;
  margin-bottom: 0 !important; }

.status-dropdown .heading-label {
  font-size: 1.6rem;
  color: #15284b; }

/* Main End */
/* Responsive Media Queries */
@media (min-width: 544px) {
  .container.container-lg {
    max-width: 576px; } }

@media (min-width: 768px) {
  .container.container-lg {
    max-width: 720px; }
  /* Yachts Result View Start*/
  /* Yachts Result View End */ }

@media (min-width: 992px) {
  .container.container-lg {
    max-width: 940px; } }

@media (min-width: 1200px) {
  .container.container-lg {
    max-width: 1552px;
    width: 100%; }
  .container.container-md {
    max-width: 1112px;
    width: 100%; } }

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  #main .selected-yachts-wrapper .selected-yachts-inner.togglable-sidebar.togglable-rsidebar-hidden {
    right: -41%; } }

@media screen and (max-width: 1440px) {
  #sidebar {
    z-index: 9999; } }

.checkbox-wrapper .checkbox input:checked + .checkmark-box.dashboard-chbx:before {
  background: #333 !important;
  border-color: #333 !important; }

/* Sales Listing */
.sales-listing-yachts-controls {
  position: absolute;
  z-index: 1999;
  left: 4px;
  top: -8px; }
  .sales-listing-yachts-controls button.control {
    background-color: white;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: 8px;
    cursor: pointer; }

textarea {
  min-height: 160px; }

.yacht-category.yacht-chartering {
  background: #5899b7 !important; }

.collapse-wrap::before {
  top: 19px; }

.companies-wrap .companies-list > li .floating-label-wrapper {
  margin-bottom: 3.2rem; }

/* width */
::-webkit-scrollbar {
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #41506e;
  border-radius: 2px; }

.w300 {
  width: 300px !important; }

h2,
h4 {
  text-transform: uppercase;
  color: #41506e;
  font-weight: 400; }

h2 {
  font-size: 1.8rem; }

h4 {
  font-size: 1.3rem; }

.customDatePicker .floating-label-wrapper {
  margin-top: 0; }

.page__actionsbar {
  z-index: 9999; }
  @media screen and (min-width: 992px) {
    .page__actionsbar {
      position: fixed;
      right: 0;
      max-width: calc(22vw); } }

.extra-options-info {
  font-size: 1.4rem;
  margin-top: 7px;
  color: #41506e; }

.Select-menu-outer {
  z-index: 9999; }
